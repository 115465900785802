@import 'assets/css/common.scss';

.upper-section {
  position: relative;
  background-color: $light-grey;
  min-height: 100vh;
}

.body {
  margin-top: 4.4rem;
  padding-bottom: 11rem;
}
