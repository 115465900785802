@import 'assets/css/common.scss';


.detail-section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @include mobile {
    margin-top: 0;
  }
}

.title {
  @include flex-column-gap(1.4rem);

  font-weight: 700;
  font-size: $fontSize-m;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  align-items: baseline;

  &-select {
    font-weight: 400;
    font-size: $fontSize-xs;
    line-height: 18px;
    color: $darkest-grey;

    @include mobile {
      margin-left: 0;
      margin-top: 0.5rem;
    }
  }

  @include mobile {
    flex-direction: column;
  }
}