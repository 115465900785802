@import 'assets/css/common.scss';

.container {
  flex: 3;
  padding: 0 3rem 0 2rem;
  display: flex;
  flex-direction: column;
  @include flex-row-gap(1rem);
  @include mobile {
    @include flex-row-gap(0.3rem);
  }
}

.bold {
  font-weight: 700;
}

.title {
  font-size: $fontSize-mml;
  line-height: 3rem;
}

.section {
  display: flex;
  line-height: 2.1rem;
  &:nth-child(2) {
    margin-right: -3rem;
  }
  @include mobile {
    &:last-child {
      margin-top: 0.2rem;
    }
  }
}

.purple {
  color: $secondary-purple;
}

.divider {
  width: 4.2rem;
  align-self: flex-start;
  margin: 1rem 0;
}

.icon {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.9rem;
  flex-shrink: 0;
}

.dot-icon {
  color: $grey;
  margin: 0 1rem;
  padding-bottom: 0.2rem;
  &-purple {
    color: $secondary-purple;
    margin: 0 0.5rem;
  }
}

.rating-container {
  display: flex;
  align-items: baseline;
  @include flex-column-gap(0.8rem);
  > :first-child {
    align-self: center;
    margin-left: 0.8rem;
  }
  > :nth-child(2) {
    font-weight: 700;
    font-size: $fontSize-s;
    line-height: 2.1rem;
    display: flex;
    color: $darkest-grey;
  }
}

.name {
  font-size: 2rem;
  font-weight: 700;
  line-height: 3rem;
}
