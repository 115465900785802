@import 'assets/css/common.scss';

.avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: $fontSize-s;
  &--img-wrapper {
    height: 9.3rem;
    width: 9.3rem;
    object-fit: cover;
    border-radius: 100%;
    margin-bottom: 1.6rem;
    @include mobile {
      height: 6rem;
      width: 6rem;
      margin-bottom: 0;
    }
  }
  &--ratings {
    display: flex;
    justify-content: center;
    margin-top: 0.7rem;
    @include mobile {
      padding-left: 0.8rem;
      margin-top: 0;
    }
  }
  &--ratings-score {
    font-weight: 700;
    margin-right: 0.2rem;
  }
  .empty-star-icon {
    margin: 0 0.2rem;
  }
  .ratings-wrapper {
    @include mobile {
      display: none;
    }
  }
}
.ratings-wrapper {
  display: flex;
  flex-direction: column;

  :global(.MuiRating-decimal) {
    width: 15px;
    height: 15px;
  }

  @include mobile {
    flex-direction: row;
    align-items: center;
  }
}

.display-none-on-mobile {
  @include mobile {
    display: none;
  }
}
