@import 'assets/css/common.scss';

.dropdown-menu {
  width: 37.5rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 5px 2px $box-shadow-elevation-07;
  background-color: $white;
  margin-top: 1rem;
  overflow: hidden;
}

.action {
  padding: 1.2rem 2.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include flex-column-gap(1.6rem);
  background-color: $white;

  box-shadow: 0px 2px 10px 2px $navigation-bottom-box-shadow;
}

.reset-btn {
  width: 8.8rem;
  height: 3.2rem;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $magenta;
  border: 1px solid $magenta;
  &:disabled {
    border: 1px solid currentColor;
  }
}

.apply-btn {
  width: 8.8rem;
  height: 3.2rem;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  padding: 0.6rem 2.5rem;
}

.main-section {
  padding: 2.4rem;
  padding-bottom: 0;
}

.header {
  display: flex;
  justify-content: space-between;

  h3 {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.7rem; /* 150% */
  }

  button {
    cursor: pointer;
    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}

.body {
  padding-top: 3rem;
  padding-bottom: 3.7rem;
}
