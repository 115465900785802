@import 'assets/css/common.scss';

.container {
  font-size: $fontSize-s;
  line-height: 2.1rem;
  margin-bottom: 1.8rem;
  margin-top: 1.4rem;
  @include mobile {
    padding: 0 2.4rem;
    position: relative;
  }
  &--left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.6rem;
    @include mobile {
      gap: 0rem;
    }
    .avatar {
      height: 6rem;
      width: 6rem;
      object-fit: cover;
      border-radius: 100%;
      @include mobile {
        width: 4rem;
        height: 4rem;
      }
    }
    .name {
      font-size: $fontSize-xxl;
      line-height: 5.4rem;
      @include mobile {
        font-size: $fontSize-mml;
        line-height: 3rem;
        text-align: left;
      }
    }
    .bold {
      font-weight: 700;
    }
    svg {
      transition: transform 150ms ease-in-out;
      margin-left: 1.6rem;
      margin-right: 0.7rem;
    }
  }
}

.icon {
  font-size: 2rem;
}

.rotate-right {
  transform: rotate(90deg);
}
.rotate-down {
  transform: rotate(180deg);
}
