@import 'assets/css/common.scss';

.container {
  color: $darkest-grey;
  background-color: $light-grey;
  padding-bottom: 1rem;
  border-bottom: 1px solid $lighter-grey;

  .point-contact-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .name {
      font-size: $fontSize-m;
      margin: 0 1rem;
    }
  }

  .caret-icon-right {
    transform: rotate(0);
  }

  .caret-icon-down {
    transform: rotate(90deg);
  }
}

.container-iframe {
  background-color: $white;
}

.transition-detailed-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  margin-top: 2rem;

  .information-container {
    width: 65%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 5rem;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        margin-left: 1rem;
      }
    }
    @include mobile {
      width: 100%;
      margin-right: 0;
      flex-direction: row-reverse;

      & > :first-child {
        min-width: 0;
        svg {
          flex-shrink: 0;
        }
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        margin-left: 1.4rem;
      }

      & > :nth-child(2) {
        white-space: pre;
      }
    }
  }

  .reason-container {
    width: 100%;
    max-width: 20rem;
  }
}

.collapsed {
  opacity: 0;
  display: none;
  visibility: hidden;
}

.expanded {
  opacity: 1;
  display: flex;
  visibility: visible;
}

.mobile-reason-dropdown {
  @include mobile {
    margin-top: 1rem;
    margin-bottom: 2.4rem;
  }
}
