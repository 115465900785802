@import 'assets/css/common.scss';

:global {
  .calendar-selector-container {
    .react-datepicker-popper {
      z-index: 10;
      @include mobile {
        transform: translate3d(-50%, 186px, 0px) !important;
        left: 50% !important;
        margin-left: 0 !important;
        width: calc(100% - 4.8rem); // content padding is 2.4rem
      }
    }

    .react-datepicker {
      box-sizing: border-box;
      width: 100%;
    }

    .react-datepicker-wrapper {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;

      .react-datepicker__input-container {
        input {
          width: 100%;
          padding: 0 0 0 0.6rem;
          font-family: 'Nexa';
          font-size: $fontSize-m;
          font-weight: bold;
          border: none;

          &:hover {
            cursor: pointer;
          }

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: $mid-grey;
          }
        }
      }
    }

    .react-datepicker__tab-loop {
      .react-datepicker {
        padding: 1.6rem 1.3rem;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0px 1px 3px 3px rgba(168, 168, 168, 0.25);

        &__triangle {
          display: none;
        }

        &__header {
          background-color: $white;
          border-bottom: none;
        }

        &__month-container {
          width: 100%;
        }

        &__month-text {
          font-family: 'Nexa';
          width: 9.2rem;
          height: 3.2rem;
          line-height: 3.2rem;
          font-size: $fontSize-m;
          font-weight: 400;
        }

        &__month-text--keyboard-selected {
          border-radius: 5rem;
          background-color: $magenta;
          color: $white;
        }
      }
    }
  }
}
