@import 'assets/css/common.scss';
@import '../BookNowPanel.module.scss';


.dropdown-section {
  display: flex;

  @include flex-column-gap(2rem);

  @include mobile {
    flex-direction: column;
    align-self: stretch;
    @include flex-column-gap(0);
  }
}

.main-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.auto-height {
  height: auto;
  padding-bottom: 2rem;
}
