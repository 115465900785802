@import 'assets/css/common.scss';

$card-padding: 4.8rem;

.container {
  width: 86rem;
  min-height: 14.6rem;
  padding: 2.4rem 0.5rem 2.4rem 2.4rem;
  margin-bottom: 1.6rem;
  background-color: $white;
  display: flex;
  border-radius: 10px;
  border: 0.5px solid transparent;
  justify-content: space-between;
  box-shadow: 0px 0px 10px rgba(217, 217, 217, 0.5);
  background: #ffffff;
  cursor: pointer;
  &.padding-top-only {
    width: unset;
    @include mobile {
      padding: 1.6rem 0 0 0;
    }
  }
  &:hover {
    box-shadow: 0px 0px 5px 2px rgba(176, 27, 174, 0.2);
    border: 0.5px solid #b01bae;
  }
  @include mobile {
    width: 100vw;
    box-sizing: border-box;
    padding: 2rem;
  }
}

.practitioner-wrapper {
  display: flex;
  width: 100%;

  @include mobile {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.practitioner-info-wrapper {
  display: flex;
  flex: 2;
  @include mobile {
    justify-content: flex-start;
  }
}
