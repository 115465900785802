@import 'assets/css/common.scss';

.map__container {
  position: fixed;
  right: 0;
  top: 0;
  margin-top: 11.05rem;
  z-index: 10;
  box-shadow: $box-shadow 0px 4px 8px 4px;
  @include mobile {
    z-index: 10;
    width: 100vw;
    margin-top: 6.4rem;
    transition: 0.5s ease-in-out;
  }
}

.hide_map {
  @include mobile {
    transform: translateX(110vw);
    transition: 0.5s ease-in-out;
  }
}

.leaflet-container {
  width: 29vw;
  height: calc(100vh - #{11rem});
  @include mobile {
    width: 100vw;
    height: calc(100dvh - #{6.4rem});
  }
}

.leaflet-left {
  left: 1.2rem !important;
}

.leaflet-control {
  border: none !important;
  box-shadow: -4px 0px 3px 0px $box-shadow-elevation-08 !important;
}

.leaflet-control-zoom-in {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;

  &::after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-size: 20px 20px;
    background-image: url('../../assets/icons/zoom-in.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
}

.leaflet-control-zoom-out {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;

  &::after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-size: 20px 20px;
    background-image: url('../../assets/icons/zoom-out.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
}

.markercluster-map {
  height: 90vh;
}

.marker-cluster-small div {
  background-color: $magenta;
  color: $white;
  font-weight: 700;
}

.marker-cluster-small {
  background-color: $primary-purple-lightest;
}
