@import 'assets/css/common.scss';

.backdrop {
  width: 100vw;
  height: 100vh;
  background-color: rgba($darkest-grey, 30%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.container {
  z-index: 10;
  width: 145px;
  height: 145px;
  background-color: $white;
  border-radius: 1rem;
  border: 1px solid $grey;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  @include flex-row-gap(1.6rem);
  justify-content: center;
  align-items: center;
  opacity: 1;
  .circular-progress {
    color: $magenta;
  }
  .info {
    font-weight: 400;
    font-size: $fontSize-s;
    line-height: 21px;
    color: #373737;
    font-family: 'Nexa';
  }
}
