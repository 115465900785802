@import 'assets/css/common.scss';

.select-time-header-section {
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
  justify-content: space-between;
  color: $darkest-grey;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }

  .title {
    & > svg {
      margin-right: 1rem;
    }

    @include mobile {
      margin-bottom: 8px;
      
      & > svg {
        margin-right: 0rem;
      }
    }
  }
}

.header-section {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  justify-content: space-between;
  color: $darkest-grey;

  @include mobile {
    flex-direction: column;
    align-items: start;
    margin-bottom: 1.6rem;
  }

  &-reverse {
    flex-direction: column-reverse;
  }
}

.mobile-container {
  @include mobile {
    display: flex;
    font-size: 1.4rem;
    line-height: 2.1rem;
    align-items: center;
    color: $darkest-grey;
    margin-bottom: 1.6rem;
    & > :nth-child(1) {
      margin-right: 1rem;
    }
  }
}

.caret-icon {
  transform: rotate(90deg);
}

.title {
  font-weight: 700;
  font-size: $fontSize-l;
  line-height: 3.6rem;
  color: $magenta;

  @include mobile {
    font-size: $fontSize-ml;
    line-height: 2.7rem;
    display: flex;
    align-items: center;
    @include flex-column-gap(1rem);
  }
}
