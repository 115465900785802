@import 'node_modules/react-datepicker/src/stylesheets/datepicker.scss';
@import 'assets/css/common.scss';

.date-selector-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .date-selector-clear-button {
    padding: 0.4rem;
    margin: 0 1rem 0.9rem 0;
    visibility: hidden;
    &.open {
      visibility: visible;
    }
  }

  .react-datepicker-wrapper {
    flex: 1;
    .react-datepicker__input-container {
      input {
        width: 100%;
        padding: 0 0 0 0.6rem;
        font-family: 'Nexa';
        font-size: $fontSize-s;
        line-height: 2.1rem;
        border: none;
        &:hover {
          cursor: pointer;
        }
        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #a9a9a9;
        }
      }
    }
  }

  .react-datepicker__tab-loop {
    .react-datepicker-popper[data-placement^='bottom'] {
      padding-top: 1.8rem;
      margin-left: -4rem;
      @include mobile {
        margin-top: -1rem;
        margin-left: -5.1rem;
        transform: translate(0, 5rem) !important;
        width: 100%;
        min-width: 31rem;
      }
    }
    .react-datepicker-popper[data-placement^='top'] {
      margin-left: -4rem;
      @include mobile {
        margin: 0;
      }
    }
    .react-datepicker {
      width: 27rem;
      padding: 1.5rem 1.2rem;
      border: none;
      border-radius: 2rem;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
        rgba(0, 0, 0, 0.24) 0px 1px 2px;
      display: flex;
      justify-content: center;
      @include mobile {
        width: 100%;
        min-width: 30rem;
      }
      &__triangle {
        display: none;
      }
      &__navigation {
        top: 2.5rem;
        &-icon::before {
          border: none;
        }
        &--next {
          background: url('../../assets/icons/right.svg') no-repeat;
          width: 1.5rem;
          height: 1.5rem;
          border: none;
          right: 2.3rem;
        }
        &--previous {
          background: url('../../assets/icons/left.svg') no-repeat;
          width: 1.5rem;
          height: 1.5rem;
          border: none;
          left: 3rem;
        }
      }
      &__header {
        background-color: #fff;
        border-bottom: none;
        .react-datepicker__current-month {
          font-family: 'Nexa';
          font-size: $fontSize-ml;
          font-style: normal;
          font-weight: bolder;
          line-height: 2.7rem;
          margin-bottom: 1.6rem;
        }
        .react-datepicker__day-names {
          font-family: 'Nexa';
          font-style: normal;
          font-weight: 700;
          font-size: $fontSize-s;
          color: $darkest-grey;
          .react-datepicker__day-name {
            width: 3rem;
            line-height: 2.1rem;
            color: $darkest-grey;
            margin: 0.2rem;
            @include mobile {
              width: 3.8rem;
            }
          }
        }
      }
      &__month {
        .react-datepicker__day--outside-month {
          visibility: hidden;
        }

        .react-datepicker__day {
          width: 3rem;
          height: 3rem;
          color: $darkest-grey;
          font-family: 'Nexa';
          font-size: $fontSize-s;
          font-style: normal;
          font-weight: 400;
          line-height: 3rem;
          margin: 0.2rem;
          @include mobile {
            width: 3.8rem;
            height: 3.8rem;
            line-height: 3.8rem;
          }

          &--disabled {
            color: $grey;
          }

          &--selected {
            &:focus-visible {
              outline: transparent;
            }
            color: $white;
            border-radius: 50%;
            background-color: $magenta;
          }

          &--keyboard-selected {
            background-color: transparent;
          }

          &--keyboard-selected.react-datepicker__day--today {
            &:focus-visible {
              outline: transparent;
            }
            color: $white;
            border-radius: 50%;
            background-color: $magenta;
          }
        }
      }
    }
  }
}
