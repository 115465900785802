@import 'assets/css/common.scss';

.section {
  margin-top: 1.9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $darkest-grey;
}

.social-section {
  margin-top: 1.9rem;
  display: flex;
  @include flex-column-gap(2.4rem);

  align-items: center;
}

.fill-section {
  margin-top: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @include flex-column-gap(0.8rem);

  &::after,
  &::before {
    content: '';
    border-top: 1px solid $lighter-grey;
    width: 8rem;
    display: inline-block;
  }
}

.form-section {
  margin-top: 2.9rem;

  .link {
    text-decoration: underline;
    color: $magenta !important;
    font-weight: 700;
  }
}

.footer-section {
  display: flex;
  margin-top: 3.2rem;
  justify-content: flex-end;
  & > :first-child {
    margin-right: 1.6rem;
  }
}

.popup {
  color: $magenta;
  font-size: $fontSize-s;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.error-container {
  display: flex;
  @include flex-column-gap(0.8rem);

  flex-wrap: wrap;
}

.resend-button {
  color: $magenta;
  margin-top: 0.8rem;
  font-weight: 700;
  font-size: $fontSize-xs;
  line-height: 1.8rem;
  text-decoration: underline;
  cursor: pointer;
  &:disabled {
    color: $mid-grey;
    cursor: not-allowed;
  }
}
