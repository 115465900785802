@import 'assets/css/common.scss';

:global(.form-control) {
  &.input {
    font-family: 'Nexa';
    padding: 2.6rem;
    padding-left: calc(5.45rem + 1.6rem);
    box-shadow: 0px 0px 5px 2px rgb(220 220 220 / 50%);
    border: 0.5px solid transparent;
    border-radius: 0.8rem;
    width: 100%;
    color: $darkest-grey;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    &::placeholder {
      color: $mid-grey;
    }
    &:hover,
    &:focus {
      border-color: $magenta;
      box-shadow: 0px 0px 5px 2px rgba(176, 27, 174, 0.2);
    }
    &.error {
      border-color: rgba(245, 34, 45, 1);
      box-shadow: unset;
    }
  }
}

:global(.flag-dropdown) {
  &.button {
    border: none;
    background: unset !important;
  }
}

:global {
  .selected-flag {
    width: 5.45rem !important;
    background-color: unset !important;
    padding-left: 1.4rem !important;
    &:hover {
      background-color: unset !important;
    }
    .flag {
      top: 49%;
    }
  }

  .dial-code {
    &::before {
      content: '+';
    }
  }
}

.container {
  font-size: $fontSize-s;
  font-family: 'Nexa';
  line-height: 2.1rem;
  border-color: $grey;
  border-radius: 0.4rem;
}

.error-message {
  font-size: $fontSize-xs;
  font-family: 'Nexa';
  line-height: 1.8rem;
  margin-right: 1.4rem;
  margin-top: 0.3rem;
  text-align: left;
  font-weight: 400;
  // Copy MUI style for consistent
  letter-spacing: 0.03333em;
}

.container {
  display: inline-flex;
  width: 100%;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
}

.input-wrapper {
  position: relative;
}

.divider {
  position: absolute;
  z-index: 1;
  display: flex;
  height: 80%;
  border-right: 0.5px solid $mid-grey;
  left: 5.45rem;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
}
