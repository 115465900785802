@import 'assets/css/common.scss';

.month-view-iframe {
  height: 100%;

  @include mobile {
    margin-bottom: 10rem;
  }
}

.slot-view-iframe {
  height: 100%;
  
  @include mobile {
    margin-bottom: 10rem;
  }
}

