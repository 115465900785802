@import 'assets/css/common.scss';

.container {
  display: flex;
  align-items: center;
}

.btn {
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
  &.disabled {
    color: $grey;
    pointer-events: none;
  }
  &-icon {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.content {
  font-weight: 700;
  font-size: $fontSize-m;
  text-align: center;
  margin: 0 5px;
  padding-top: 0.3rem;
  cursor: pointer;
  width: 14rem;
  @include mobile {
    margin: 0px;
  }
}
