@import 'assets/css/common.scss';

.details-container {
  padding: 0 1rem 0 2rem;
  flex: 5;
  max-width: 420px;

  @include mobile {
    max-width: 70%;
    padding: 0 0 0 1.6rem;
  }
}
.practitioner-name-study {
  width: 100%;
  font-size: $fontSize-mml;
  line-height: 3rem;
  font-size: 1.6rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @include mobile {
    flex: unset;
    font-size: $fontSize-ml;
  }
}
.practitioner-specialty {
  font-size: $fontSize-s;
  font-weight: 700;
  line-height: 2.1rem;
  color: $secondary-purple;
  margin: 0.5rem 0;
  @include mobile {
    word-wrap: normal;
    white-space: normal;
  }
}

.practitioner-description {
  font-size: $fontSize-s;
  font-weight: 300;
  line-height: 2.1rem;
  margin-bottom: 1.8rem;
  white-space: pre-line;
  padding-right: 2.4rem;

  a {
    text-decoration: underline !important;
    font-weight: 700 !important;
    color: $primary-purple !important;
  }
}

.contact-container {
  &.hidden {
    display: none;
  }
}

.request-btn {
  width: 26rem;
  height: 4rem;
  &.small {
    width: 20rem;
  }
  @include mobile {
    display: none;
  }
}

.flex-responsive-wrapper {
  display: flex;
  margin: 2rem 0 1.4rem 0;
  @include mobile {
    margin: 0rem 0rem 1.6rem 0rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .flex-align-center {
      margin-bottom: 0.6rem;
    }
  }
  .ratings-section {
    display: none;
    @include mobile {
      display: block;
      margin-left: 1rem;
    }
  }
}
.flex-align-center {
  display: flex;
  align-items: center;
  @include mobile {
    align-items: unset;
  }
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.address-container {
  display: flex;
  flex-direction: row;
  .address-section {
    @include mobile {
      flex: 2;
    }
  }
}

.display-none-mobile {
  @include mobile {
    display: none;
    &.show-on-mobile {
      display: unset;
    }
  }
}

.bold {
  font-weight: 700;
}

.dot-icon {
  margin: 0 0.8rem;
  color: $darkest-grey;
  padding-bottom: 0.25rem;
}

.dot-seperator {
  color: $grey;
}

.truncate {
  @include mobile {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.info-icon {
  width: 1.6rem;
  height: 2rem;
  margin-right: 0.9rem;
  flex-shrink: 0;
  @include mobile {
    height: 1.6rem;
    flex: 0.5;
  }
}

.truncate {
  @include mobile {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
