.wrapper {
  padding: 2.4rem;
  padding-bottom: 0;

  button {
    display: flex;
    margin-left: auto;
    cursor: pointer;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
}
