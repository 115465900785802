@import 'assets/css/common.scss';

.container {
  background-color: $light-grey;
  position: relative;
  padding-bottom: 11rem;
  min-height: 100dvh;
}

.body {
  margin-top: 2rem;
}
