@import 'assets/css/common.scss';

.month-view-section {
  margin-top: 4.5rem;


  & > div:first-child {
    padding-bottom: 1.1rem;
    border-bottom: 1px solid #cbcbcb;
  }

  @include mobile {
    margin-top: 2rem;
  }
}

.month-view-section-iframe {
  display: flex;
  flex-direction: column;
  height: calc(100% - 150px);
  

  :global(.calendar-header-detail-page) {
    background-color: $white !important;
  }

  :global(#month-view-search) {
    overflow: auto;
  }

  @include mobile {
    height: auto;
  }
}

.navigation-controller {
  display: flex;
  justify-content: end;
  margin-top: 2.9rem;
  padding-bottom: 2.4rem;
  @include flex-column-gap(1.6rem);
  button {
    width: 20rem;
    height: 4rem;
  }

  @include mobile {
    justify-content: center;
  }
}
