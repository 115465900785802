@import 'assets/css/common.scss';

.modal {
  width: 100%;
  max-width: 48rem;

  color: $darkest-grey;

  .container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }
  .title {
    font-size: 2rem;
    line-height: 3rem;
  }

  .info {
    margin-top: 1.6rem;
    margin-bottom: 2.4rem;
    line-height: 21px;
  }

  .btn-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1.6rem;

    @include mobile {
      justify-content: center;
    }
  }

  .btn {
    @include mobile {
      width: 100%
    }
  }
}
