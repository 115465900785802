@import 'assets/css/common.scss';

.cellWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dayNumber {
  font-size: $fontSize-m;

  @include mobile {
    font-size: $fontSize-s;
  }
}

.monthNumber {
  font-size: $fontSize-xs;
  color: $mid-grey;
}

.slot {
  font-size: $fontSize-xxs;
  text-align: center;
  padding-top: 0.5rem;

  @include mobile {
    font-size: $fontSize-smaller-xxs;
  }
}

.openSlot {
  @extend .slot;
  color: $magenta;
  @include mobile {
    font-size: 1rem;
    line-height: 1.8rem;
  }
}

.closedSlot {
  @extend .slot;
  color: $mid-grey;
}

.emptySlot {
  @extend .slot;
  color: $mid-grey;
}

.loading {
  width: 4rem;
  height: 1rem;
  border-radius: 10rem;
  animation: loading-animation infinite 1s;
}

@keyframes loading-animation {
  from {
    background: linear-gradient(90deg, #f0e0f0 0%, rgba(250, 228, 250, 0) 100%);
  }

  to {
    background: #f0e0f0;
  }
}
