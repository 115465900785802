@import 'assets/css/common.scss';

.circular-progress {
  margin: 10rem auto 0 auto;
  color: $magenta;
}

.grid-container-header {
  display: grid;
  grid-template-columns: repeat(5, 12rem);
  margin-top: 1.6rem;
  column-gap: 2.7rem;
  position: relative;

  @include mobile {
    grid-template-columns: repeat(3, 10rem) 1px;
    justify-content: space-between;
    column-gap: 0;
  }

  .grid-column {
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .label-sm {
    font-size: $fontSize-s;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: $darkest-grey;
  }
  .label-md {
    font-size: $fontSize-m;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.grid-container-body {
  display: grid;
  grid-template-columns: repeat(5, 12rem);
  column-gap: 2.7rem;
  margin-top: 0.4rem;
  padding-top: 0.4rem;
  margin-right: -1.8rem;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  margin-bottom: 5.5rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  margin-left: -0.5rem;

  @include mobile {
    grid-template-columns: repeat(3, 10rem) 1px;
    justify-content: space-between;
    column-gap: 0;
    margin-right: -6px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $light-mid-grey;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-button {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &-iframe {
    margin-bottom: 1.5rem;
  }
}

.hide-scrollbar {
  overflow-y: hidden;
}

.column-container {
  display: flex;
  flex-direction: column;
  @include flex-row-gap(1.6rem);
  align-items: center;

  button.timeslot-none {
    &:hover {
      border: none;
    }
  }
}

.timeslot {
  font-family: 'Nexa';
  background: #fff;
  display: flex;
  width: 100%;
  height: 4rem;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: $darkest-grey;
  font-style: normal;
  font-weight: 400;
  font-size: $fontSize-s;
  line-height: 21px;
  border: none;
  box-shadow: 0px 0px 5px 2px $smaller-btn-box-shadow;
  &:hover {
    background-color: $light-pink;
    border-color: $light-pink;
  }
  @include mobile {
    width: 10rem;
  }
}

.timeslot-none {
  width: 12rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.animate-icon {
  position: absolute;
  left: calc(50% - 2rem);
  text-align: center;
  animation: move-down 1s infinite;
  z-index: 2;
  transition: 0.5s all;
  opacity: 1;
  &-remove {
    opacity: 0;
    z-index: 0;
    height: 0;
  }
}

.blur-bg-bottom {
  width: calc(100% - 2.5rem - 1rem);
  height: 4rem;
  position: absolute;
  bottom: 5.2rem;
  margin: 0 auto;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(249, 249, 249, 0.05) 0%,
    rgba(249, 249, 249, 0.9) 65.62%,
    #f9f9f9 100%
  );

  &-iframe {
    background: linear-gradient(
      180deg,
      rgba(249, 249, 249, 0) 0%,
      rgba(254, 254, 254, 0.85) 74.48%,
      #ffffff 100%
    );
    bottom: 1rem;
  }
}

.blur-bg-top {
  width: calc(100% + 1rem);
  height: 3rem;
  position: absolute;
  bottom: -3rem;
  z-index: 1;
  background: linear-gradient(
    180deg,
    $light-grey 0%,
    rgba(249, 249, 249, 0) 100%
  );

  &-iframe {
    background: linear-gradient(
      360deg,
      rgba(249, 249, 249, 0.2) 0%,
      $white 100%
    );
  }
}

@keyframes move-down {
  from {
    bottom: 2.2rem;
  }
  to {
    bottom: 1.7rem;
  }
}

.emptyslot-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: 1 / -1;

  @include flex-row-gap(2rem);

  span {
    font-size: 1.4rem;
  }

  margin-top: 8rem;
}

.next-availability-button {
  min-width: fit-content;
  margin-top: 1.6rem;
  text-transform: initial;
}
