@import 'assets/css/common.scss';

.wrapper-iframe {
  width: 100%;
  padding: 0 2.8rem;
  @include mobile {
    padding: 0;
  }
}

.container {
  width: 70rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background: $white;

  &-iframe {
    width: 68.5rem;
  }

  border: 1px solid $lighter-grey;
  border-radius: 1.6rem;
  @include mobile {
    width: calc(100vw - 1.6rem);
  }
}

.header {
  font-weight: 700;
  font-size: $fontSize-mml;
  line-height: 30px;
  color: $darkest-grey;
  font-family: 'Nexa';
  padding: 1.6rem 0 0.8rem 3rem;
  border-bottom: 1px solid $lighter-grey;
}

.body {
  padding: 0 3rem;
  padding-top: 2.4rem;
  display: flex;
  flex-direction: column;
  @include mobile {
    flex-direction: column-reverse;
  }
  &-group {
    display: flex;
    @include flex-column-gap(5rem);

    @include mobile {
      flex-direction: column;
      @include flex-column-gap(0);
    }

    & > * {
      flex: 1;
    }
  }
}

.footer {
  width: 24rem;
  margin: 0 auto;
  margin-bottom: 4.3rem;
  font-weight: 400;
  font-size: $fontSize-s;
  line-height: 2.1rem;
  text-align: center;
  color: $darkest-grey;
  &-button {
    margin-top: 2.4rem;
    margin-bottom: 1.6rem;
    @include mobile {
      width: 100%;
      height: 4rem;
    }
  }
  @include mobile {
    margin-top: 1.6rem;
    margin-bottom: 2rem;
  }
}

.navigate-button {
  color: $magenta;
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}

.mt-8 {
  margin-top: 0.8rem;
}

.action-btn {
  margin: 0 0 0 2.4rem;
  &-container {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    box-shadow: $box-shadow 0px 2px 5px 0px;
    padding: 2rem 8rem;
    @include mobile {
      width: 100%;
      justify-content: flex-end;
      background-color: $white;
      box-shadow: $box-shadow 0px 2px 5px 0px;
      padding: 2rem 1.6rem 2rem 0rem;
      position: fixed;
      bottom: 0;
      left: -1.6rem;
    }
  }
}

.mt-29 {
  margin-top: 2.9rem;
}

.display-none-on-mobile {
  margin-top: 2rem;
  @include mobile {
    display: none;
  }
}

.display-none-on-desktop {
  display: none;
  @include mobile {
    display: block;
    margin: 1rem 0rem;
  }
}

.submit-button-on-sign-up-page {
  display: flex;
  justify-content: center;
  padding-bottom: 8.1rem;
}
