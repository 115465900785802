@import 'assets/css/common.scss';

.container {
  width: 100%;
  padding-left: calc((100% - 128rem) / 2);
  font-size: $fontSize-m;
  font-weight: 700;
  height: 6.4rem;
  display: flex;
  align-items: end;
  background-color: $white;
  z-index: 10;
  border-bottom: 1px solid $lighter-grey;

  @include mobile {
    padding: 0 2.4rem;
    height: 4rem;
  }
}

.button {
  color: $darkest-grey;
  margin-right: 6.7rem;
  height: 3.6rem;
  align-items: center;
  display: flex;
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 700;
  &.active {
    color: $magenta;
    border-bottom: 2px solid $magenta;
  }
  @include mobile {
    margin: auto;
    height: 3.2rem;
  }
}
