@import 'assets/css/common.scss';

.sign-up-option {
  width: 452px;
  height: 290px;
  background: $white;
  border-radius: 1rem;
  @include mobile {
    width: calc(100vw - 6.4rem);
  }
}

.header {
  display: flex;
  font-weight: 700;
  font-size: $fontSize-ml;
  line-height: 2.7rem;
  color: $darkest-grey;
  font-family: 'Nexa';
  padding: 2.4rem;
  justify-content: space-between;
}

.close-icon {
  margin-top: -0.4rem;
  cursor: pointer;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include flex-row-gap(1.1rem);
  font-size: $fontSize-s;
  height: calc(100% - 12rem);
}

.option {
  color: $magenta;
  border: 1px solid $magenta;
  border-radius: 10rem;
  width: 20rem;
  height: 4rem;
  font-weight: 700;
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  &-divider {
    font-weight: 400;
    color: $darkest-grey;
    font-family: 'Nexa';
    margin: 0.3rem 0;
  }
  svg {
    position: absolute;
  }
  &-icon {
    left: 1.6rem;
  }
}
