@import 'assets/css/common.scss';

.container {
  font-size: $fontSize-s;
  line-height: 2.1rem;
  padding-bottom: 2.4rem;
  display: flex;
  flex-direction: column;
  @include mobile {
    position: relative;
  }
}

.booking-button {
  width: 29.9rem;
  @include mobile {
    display: none;
  }
  &-wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}
.responsive-booking-button {
  width: 32.7rem;
  &-container {
    display: none;
    position: fixed;
    bottom: 0;
    height: 7.2rem;
    width: 100%;
    background-color: white;
    border-top: 1px solid $lighter-grey;
    z-index: 900;
    @include mobile {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.wrapper {
  display: flex;
  gap: 3rem;
  text-align: left;
  @include mobile {
    flex-direction: column;
  }

  &-left {
    min-width: 750px;
    flex: 0.6;
    @include mobile {
      min-width: 375px;
    }
  }

  &-right {
    flex: 0.4;
  }

  // padding: 1rem 52rem 3rem 0;
  // position: relative;
  // height: calc(100vh - 15rem);
  // overflow-y: auto;
  // @include mobile {
  //   padding: 0 2.4rem 7.3rem 2.4rem;
  // }
}

// .divider {
//   margin: 2.4rem 0;
// }

.back-to-top {
  position: fixed;
  right: 55rem;
  bottom: 4rem;
  @include mobile {
    display: none;
  }
}

.loading-circle {
  align-self: center;
  margin: 3rem 0 0 0;
}
