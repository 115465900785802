@import 'assets/css/common.scss';

.to-top-text {
  margin: 0.2rem 0 0 0;
  font-size: 1rem;
  font-weight: 300;
  line-height: normal;
  &-bold {
    font-size: 1rem;
    font-weight: 700;
    line-height: normal;
  }
}
.to-top-button {
  width: 0;
  height: 0;
  border-radius: 10px;
  position: fixed;
  z-index: 2;
  bottom: 5rem;
  transition: all ease-in 0.5;
  opacity: 0;
  right: 2.8rem;
}

.appear {
  width: 5.2rem;
  height: 5.2rem;
  opacity: 1;
}

.icon {
  width: 1.6rem;
  height: 1.6rem;
  & > path {
    fill: $magenta;
  }
}

.label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.observer {
  position: absolute;
  top: 1500px;
  width: 10px;
  height: calc(100% - 1500px);
  opacity: 0;
  z-index: -1;
}

.search-result-position {
  right: auto;
  margin-left: 80rem;
  @include mobile {
    right: 2.5rem;
    margin-left: 0;
  }
}
