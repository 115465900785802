@import 'assets/css/common.scss';

.container {
  background-color: $light-grey;
  padding: 1.8rem 2.4rem;
  padding-bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 70rem;
  position: relative;
}

.navigation-container {
  margin-top: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .navigation-section {
    display: flex;
    @include flex-column-gap(1rem);
  }
  .navigation-btn {
    font-family: 'Nexa';
    font-style: normal;
    font-weight: 700;
    font-size: $fontSize-s;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    border: none;
    border-radius: 8px;
    background-color: #fff;
    padding: 0.45rem 1.8rem;
    cursor: pointer;
    box-shadow: 0px 0px 5px 2px $smaller-btn-box-shadow;
    &-inactive {
      background-color: transparent;
      color: $darkest-grey;
      font-weight: 400;
      box-shadow: none;
    }
  }
}

.title {
  font-weight: 700;
  font-size: $fontSize-l;
  line-height: 3.6rem;
  color: $magenta;
  margin-bottom: 0.8rem;
  @include mobile {
    font-size: $fontSize-ml;
    line-height: 2.7rem;
  }
}

.divider {
  width: 100%;
  margin-top: 0.5rem;
}

.section-container {
  display: flex;
  @include flex-column-gap(2.8rem);
  align-items: flex-start;
  & > * {
    width: 34rem;
    margin-top: 0;
    @include mobile {
      width: 100%;
    }
  }
}
