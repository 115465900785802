@import 'assets/css/common.scss';

.container {
  & > *:not(:last-child) {
    margin-bottom: 1.6rem;
  }
  & > div.no-result {
    font-size: $fontSize-m;
    font-weight: 400;
    color: $darkest-grey;
    margin-bottom: 2.4rem;
    @include mobile {
      padding: 0 2rem;
    }
  }
  & > div.latest-availability {
    font-size: $fontSize-m;
    font-weight: 700;
    color: $darkest-grey;
    margin-bottom: 1.6rem;
    @include mobile {
      padding: 0 2rem;
    }
  }

  padding-bottom: 1.6rem;
}

.circular-loader-wrapper {
  width: '100%';
  text-align: center;

  .circular-loader {
    margin: 0 auto;
  }
}

.modal-wrapper {
  display: flex;
  width: 90rem;
  display: flex;
  height: 77rem;
  @include mobile {
    width: unset;
    height: auto;
  }
}

.body-container {
  display: -webkit-box;
  max-width: 22.6rem;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-top: 1rem;
}

.line {
  width: 42px;
  height: 1px;
  background: #c2c1c1;
  margin: 1.6rem 0;
}

.text {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  color: $darkest-grey;
}

.center-content {
  display: flex;
  justify-content: center;
}

.load-more-button {
  width: 20rem;
  height: 4rem;
  & > span > [role='progressbar'] {
    color: $magenta;
  }
}

.no-more-result {
  font-size: 1.4rem;
  line-height: 2.1rem;
}

.filter-no-result {
  font-size: 1.6rem;
  line-height: 2.4rem;
  button {
    margin-left: 0.8rem;
    font-size: inherit;
    line-height: inherit;
    text-decoration: underline;
    color: $magenta;
    font-weight: 700;
    cursor: pointer;
  }
  @include mobile {
    padding: 0 2rem;
  }
}
