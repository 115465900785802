@import 'assets/css/common.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  margin-right: -2.4rem;
  padding-right: 2.4rem;
  margin-left: -2.4rem;
  padding-left: 2.4rem;
  margin-top: 2.8rem;
  padding-bottom: 12rem;

  &::-webkit-scrollbar {
    width: '6px';
  }

  &::-webkit-scrollbar-thumb {
    background: $light-mid-grey;
    border-radius: '2rem';
  }

  &::-webkit-scrollbar-track {
    background: 'transparent';
    margin-top: '8px';
    margin-bottom: '8px';
  }

  &-iframe {
    font-size: $fontSize-s;
  }
}
