@import 'assets/css/common.scss';

.container {
  display: flex;
  width: 100%;
  background-color: $white;
  height: 5.6rem;
  padding-left: calc((100% - 128rem) / 2);
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: $darkest-grey;

  border-bottom: 1px solid $lighter-grey;

  @include flex-column-gap(1.2rem);
  @include mobile {
    padding: 0 2rem;
    overflow-y: auto;
  }
}

.btn {
  border-radius: 0.8rem;
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.2rem;
  border: 1px solid transparent;
  &[data-filter='sort'] {
    width: 6.4rem;
  }
  &[data-filter='gender'] {
    width: 8rem;
  }
  &[data-filter='rating'] {
    width: 7rem;
  }
  &[data-filter='time'] {
    width: 6.8rem;
  }
  &[data-filter='all'] {
    width: 11.1rem;
  }

  &[data-active='true'] {
    color: $magenta;
  }

  &[data-open='true'] {
    border: 1px solid $magenta;
    box-shadow: 0px 0px 5px 2px $box-shadow-elevation-10-hover;
  }

  box-shadow: 0px 0px 5px 2px $box-shadow-elevation-07;
}

.group {
  display: flex;
  border-radius: 1rem;
  background: $white;

  height: 5rem;
  overflow: hidden;

  box-shadow: 0px 0px 5px 2px $box-shadow-elevation-07;

  & > button {
    flex: 1;
    cursor: pointer;
    &[data-active='true'] {
      background-color: $active-filter;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    line-height: 2.1rem;
    text-transform: capitalize;
  }

  & > button:not(:last-child) {
    border-right: 1px solid $lighter-grey;
  }
}

.icon {
  margin-left: 0.8rem;
  width: 1.6rem;
  height: 1.6rem;
  & > g > path {
    fill: $magenta;
  }
}

.reset-btn {
  margin-left: 37rem;
  color: $magenta;
  font-size: 1.4rem;
  line-height: 2.1rem;
  cursor: pointer;
  @include mobile {
    margin-left: 3rem;
    white-space: nowrap;
  }
}
