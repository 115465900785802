@import 'assets/css/common.scss';

.container {
  width: 70rem;
  font-size: $fontSize-s;
  line-height: 2.1rem;
  margin: 0 auto 1.6rem auto;
  background-color: $white;
  border: 1px solid $lighter-grey;
  border-radius: 16px;
  margin-top: 2.4rem;
  box-sizing: border-box;

  @include mobile {
    width: 100%;
    border: none;
    border-radius: 0;
    margin-top: 10.4rem;
    background-color: transparent;
    padding: 0 2.4rem;
  }

  button {
    width: 13.6rem;
  }

  .continueBtn {
    width: 100%;
    max-width: 30rem;
  }
}

.wrapper {
  padding-top: 4rem;
  padding-bottom: 3.8rem;
  color: $darkest-grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 350px;

  @include mobile {
    padding: 4rem 0;
    background-color: $white;
    box-shadow: 0px 0px 5px 2px rgba(230, 230, 230, 0.4);
    border-radius: 1rem;
    max-width: none;
  }
}

.title {
  font-weight: bold;
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 25px;
  text-align: center;

  @include mobile {
    width: 60%;
  }
}
