@import 'assets/css/common.scss';

.container {
  display: flex;
  flex-direction: column;
  font-size: $fontSize-s;
  line-height: 2.1rem;
  margin-top: 2.4rem;
}

.large-text {
  font-size: $fontSize-l;
  @include mobile {
    font-weight: 700;
    font-size: $fontSize-ml;
    line-height: 2.7rem;
  }
}

.text-secondary-purple {
  color: $secondary-purple;
}

.review-summary-container {
  align-items: center;
  @include flex-column-gap(1.6rem);
  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 2.4rem;
    margin-bottom: 1.6rem;
  }
  .flex-row {
    @include flex-column-gap(1rem);
    align-items: center;
    @include mobile {
      margin-top: 1rem;
    }
  }
}

.average-score {
  font-size: $fontSize-l;
}

.bold {
  font-weight: 700;
}

.title {
  font-size: $fontSize-mml;
  line-height: 3rem;
  margin-bottom: 1.8rem;
  color: $secondary-purple;
  @include mobile {
    color: $secondary-purple;
  }
}

.flex-row {
  display: flex;
}

.rating-stars {
  padding-bottom: 0.5rem;
}

.select {
  align-self: flex-end;
  @include mobile {
    align-self: flex-start;
    margin: 1.6rem 0 2.4rem 0;
  }
}
