@import 'assets/css/common.scss';

.close-button {
  display: none;
  position: absolute;
  width: max-content;
  height: max-content;
  padding: 0;
  @include mobile {
    display: block;
    position: relative;
    align-self: flex-end;
  }
}

.title-on-mobile {
  display: none;
  @include mobile {
    display: block;
    position: relative;
    width: max-content;
    height: max-content;
    padding: 0;
    font-size: $fontSize-mml;
    font-weight: 700;
    font-family: 'Nexa';
    margin: 0;
  }
}

.responsive-navbar-container {
  @include mobile {
    display: none;
  }
}

.search-bar-mobile {
  @include mobile {
    margin-top: 5rem;
  }
}

.search-container {
  display: flex;
  flex-direction: column;
  padding: 0 1.7rem;
  margin-top: 2rem;
}
