@import 'assets/css/common.scss';

.container {
  background-color: $light-grey;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: $fontSize-s;
  line-height: 2.1rem;
  @include mobile {
    justify-content: flex-end;
  }
}

.content-container {
  margin: 49px auto 84px;
  width: 39.4rem;
  background-color: $white;
  border-radius: 10px;
  box-shadow: $light-box-shadow 0px 4px 8px 4px;
  padding: 4rem 6.3rem 4.7rem 6.3rem;
  display: flex;
  flex-direction: column;
  @include mobile {
    flex: 1;
    padding: 0 0;
    padding-top: 4rem;
    width: 100%;
    border: none;
    box-shadow: none;
    align-items: center;
  }
}

.confirm-icon {
  align-self: center;
}

.header {
  &-title {
    margin: 2.4rem 0 0.8rem 0;
    line-height: 2.4rem;
    font-weight: 700;
    text-align: center;
    @include mobile {
      width: 90%;
    }
  }
  &-message {
    text-align: center;
    margin-bottom: 4.3rem;
    @include mobile {
      width: 90%;
    }
  }
}

.divider {
  width: 100%;
  @include mobile {
    width: 80%;
  }
}

.details {
  align-self: flex-start;
  width: 100%;
  @include mobile {
    align-self: center;
    width: 80%;
  }
  &-title {
    text-align: left;
    color: $secondary-purple;
    margin: 2.5rem 0 1.4rem 0;
    line-height: 2.4rem;
    font-weight: 700;
  }
  &-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0 0;
    }
    &-right {
      flex: 1;
      text-align: left;
      margin-left: 2.8rem;
      p {
        font-weight: 700;
      }
      @include mobile {
        margin-left: 0;
      }
    }
    &-left {
      flex: 1;
      text-align: left;
    }
  }
}

.button {
  margin-top: 4rem;
  margin-bottom: 2rem;
  align-self: center;
}

.back-to-top {
  position: fixed;
  right: 8rem;
  bottom: 10rem;
  @include mobile {
    display: none;
  }
}
