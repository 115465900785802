@import 'assets/css/common.scss';

.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
}

.practitioner-profile-icon {
  flex-shrink: 0;
  width: 1.6rem;
  height: 1.6rem;
}

.description {
  font-size: $fontSize-s;
  line-height: 21px;
  margin-left: 8px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $light-mid-grey;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-button {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
