@import 'assets/css/common.scss';

:global {
  .day-selector-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-family: 'Nexa';
    .react-datepicker-wrapper {
      flex: 1;
    }

    .react-datepicker__tab-loop {
      .react-datepicker-popper {
        z-index: 3;
        @include mobile {
          width: calc(100% - 4.8rem); // content padding is 2.4rem
        }
      }
      .react-datepicker {
        padding: 1.5rem 1.2rem;
        border: none;
        border-radius: 0.8rem;
        box-shadow: 0px 1px 3px 3px rgba(168, 168, 168, 0.25);

        &__triangle {
          display: none;
        }
        &__navigation {
          top: 2.7rem;
          &-icon::before {
            border: none;
          }
          &--next {
            background: url('../../assets/icons/right.svg') no-repeat;
            width: 2rem;
            height: 2rem;
            border: none;
            right: 2rem;
          }
          &--previous {
            background: url('../../assets/icons/left.svg') no-repeat;
            width: 2rem;
            height: 2rem;
            border: none;
            left: 3rem;
          }
        }
        &__header {
          background-color: $white;
          border-bottom: none;
          .react-datepicker__current-month {
            font-family: 'Nexa';
            font-size: $fontSize-ml;
            font-style: normal;
            font-weight: bolder;
            line-height: 2.7rem;
            margin-bottom: 1.6rem;
          }
          .react-datepicker__day-names {
            font-family: 'Nexa';
            font-style: normal;
            font-weight: 700;
            font-size: $fontSize-s;
            color: $darkest-grey;

            .react-datepicker__day-name {
              width: 3rem;
              line-height: 2.1rem;
              color: $darkest-grey;
              margin: 0.5rem;
            }
          }
        }
        &__month {
          .react-datepicker__day--outside-month {
            visibility: hidden;
          }
          .react-datepicker__day {
            width: 3rem;
            height: 3rem;
            color: $darkest-grey;
            font-family: 'Nexa';
            font-size: $fontSize-s;
            font-style: normal;
            font-weight: 400;
            line-height: 3rem;
            margin: 0.5rem;
            &--disabled {
              color: $grey;
            }
            &--selected {
              &:focus-visible {
                outline: transparent;
              }
              color: $white;
              border-radius: 50%;
              background-color: $magenta;
            }
            &--keyboard-selected {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
