@import 'assets/css/common.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-btn {
  color: $darkest-grey;
  &.disabled {
    color: $grey;
  }
}

.navigateBtn {
  width: 4rem;
}