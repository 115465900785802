@import 'assets/css/common.scss';

.navigation-container {
  display: flex;
  justify-content: flex-end;
  @include flex-column-gap(1.6rem);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 10rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, $white 24%);
  padding-top: 3rem;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  z-index: 2;

  @include mobile {
    bottom: 18px;

    & > button {
      flex: 1;
      height: 4rem;
    }
  }
}

.navigation-container-iframe {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, $white 24%);
  position: fixed;
  bottom: 6rem;
}
