@import 'assets/css/common.scss';

.container {
  margin-left: 2.5rem;

  @include mobile {
    margin-top: 8px;
    margin-left: 0;
  }
}

.is-expanded {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;

  @include mobile {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

.is-collapsed {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4rem;

  @include mobile {
    gap: 2rem;
  }
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;

  .ordinalNumber {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 2.4rem;
    text-align: center;
    line-height: 2.4rem;
    font-size: $fontSize-medium-xxs;
    cursor: pointer;
  }

  .bookingInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $fontSize-medium-s;
  }
}

.isActive {
  .ordinalNumber {
    border: 1px solid $magenta;
    background-color: $magenta;
    color: $white;
  }

  .bookingInfo {
    color: $darkest-grey;
  }
}

.isSelecting {
  .ordinalNumber {
    border: 1px solid $magenta;
    background-color: $white;
    color: $magenta;
  }

  .bookingInfo {
    color: $darkest-grey;
  }
}

.isNoneSelecting {
  .ordinalNumber {
    border: 1px solid $second-lightest-grey;
    background-color: $white;
    color: $second-lightest-grey;
  }

  .bookingInfo {
    color: $darkest-grey;
  }
}

.fullPatientName {
  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include mobile {
    max-width: 6rem;
  }
}

.truncatePatientName {
  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include mobile {
    max-width: 6rem;
  }
}

.text-highlight {
  font-weight: bold;
}

.isNextSlotSelecting {
  .ordinalNumber {
    border: 1px solid $magenta;
    background-color: $white;
    color: $magenta;
  }

  .bookingInfo {
    color: $darkest-grey;
  }
}

.selected-date {
  margin-left: 0.4rem;
  font-weight: normal;
}
