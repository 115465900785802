@import 'assets/css/common.scss';

.icon {
  width: 1.6rem;
  height: 1.6rem;
  flex-shrink: 0;
}

.text {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  color: $darkest-grey;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.row {
  display: flex;
  @include flex-column-gap(0.8rem);
}

.container {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  @include flex-row-gap(1.6rem);
  &-availability {
    @include flex-row-gap(1rem);
  }
}

.bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}
