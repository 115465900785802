@import 'assets/css/common.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 2.4rem;
  @include mobile {
    padding: 0;
  }
}

.left {
  display: flex;
  flex-direction: column;
  flex: 2;
  position: relative;
  margin-right: 1.5rem;
  @include mobile {
    margin-right: 0;
    padding: 2.4rem;
    padding-top: 3.9rem;
  }
}

.right {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  flex: 1;
  @include mobile {
    display: none;
  }
}

.clinic-info-wrapper {
  display: flex;
  height: max-content;
  @include mobile {
    align-items: center;
  }
}

.card-avatar {
  width: 10rem;
  height: 10rem;
}

.circular-progress {
  margin: auto auto;
}

.divider {
  margin: 1.6rem 0;
  width: 100%;
}

.time-blocks-wrapper {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 0 2.4rem;
}

.dayview-navigation {
  display: flex;
  padding: 0 2.4rem;
  margin-top: 2.2rem;

  &-header {
    padding: 0 2.4rem;
    grid-template-columns: repeat(5, 8.8rem);
    column-gap: 1rem;

    @include mobile {
      padding: 0 0.1rem;
      grid-template-columns: repeat(3, 10rem);
    }
  }
  &-body {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    position: relative;
    grid-template-columns: repeat(5, 8.8rem);
    column-gap: 1rem;

    button {
      height: 3.6rem;

      &:hover {
        border: 1px solid $magenta;
        background-color: $white;

        @include mobile {
          border: none;
        }
      }

      &:active {
        @include mobile {
          border: 1px solid $magenta;
        }
      }
    }

    @include mobile {
      position: relative;
      padding: 0 0.1rem;
      padding-top: 1rem;
      margin: 0;
      grid-template-columns: repeat(3, 10rem);
    }
  }
  &-blur-top-bg {
    width: calc(100% - 2.4rem);
    background: linear-gradient(
      360deg,
      rgba(249, 249, 249, 0.2) 0%,
      $white 100%
    );
  }
  &-blur-bottom-bg {
    width: calc(100% - 4.8rem);
    margin-left: 2.4rem;
    background: linear-gradient(
      180deg,
      rgba(249, 249, 249, 0) 0%,
      rgba(254, 254, 254, 0.85) 74.48%,
      $white 100%
    );
    bottom: 1rem;
    @include mobile {
      bottom: 0;
    }
  }
  &-icon {
    left: calc(50% - 1rem);
  }
}

.navigation-container {
  margin-top: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .navigation-section {
    display: flex;
    @include flex-column-gap(1rem);
  }
  .navigation-btn {
    font-family: 'Nexa';
    font-style: normal;
    font-weight: 700;
    font-size: $fontSize-s;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    border: none;
    border-radius: 8px;
    background-color: #fff;
    padding: 0.45rem 1.8rem;
    cursor: pointer;
    box-shadow: 0px 0px 5px 2px $smaller-btn-box-shadow;
    &-inactive {
      background-color: transparent;
      color: $darkest-grey;
      font-weight: 400;
      box-shadow: none;
    }
  }
}

.info {
  @include mobile {
    display: none;
  }
}
