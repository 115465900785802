@import 'assets/css/common.scss';

.container {
  background-color: $light-grey;
  font-family: 'Nexa';
  min-height: 100dvh;
}

.header {
  @include mobile {
    position: fixed;
    width: 100%;
    z-index: 100;
  }
}

.content-container {
  padding: 3rem 0 11rem 0;
  display: flex;
  flex-direction: column;
  font-size: $fontSize-s;
  line-height: 2.1rem;

  @include mobile {
    width: auto;
    padding: 6.4rem 0 10rem 0;
  }
}

.confirm {
  &-container {
    width: 70rem;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background: $white;
    color: $darkest-grey;

    border: 1px solid $lighter-grey;
    border-radius: 1.6rem;
    @include mobile {
      width: calc(100vw - 1.6rem);
      margin-top: 2rem;
    }
  }

  &-body {
    padding: 0 3rem;
    padding-top: 2rem;
    padding-bottom: 3.8rem;
    display: flex;
    flex-direction: column;
  }

  &-header {
    font-weight: 700;
    font-size: $fontSize-mml;
    line-height: 30px;
    color: $darkest-grey;
    font-family: 'Nexa';
    padding: 1.6rem 0 0.8rem 3rem;
    border-bottom: 1px solid $lighter-grey;
  }

  &-description {
    font-size: $fontSize-s;
    font-weight: 400;
    margin-bottom: 3rem;
    line-height: 21px;
  }

  &-btn {
    align-self: center;
    width: 100%;
    max-width: 15rem;
    &-loading {
      width: 21.4rem;
    }
  }
}
