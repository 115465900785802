@import 'assets/css/common.scss';

.container {
	display: flex;
	justify-content: space-between;
  @include mobile {
    flex-direction: column;
  }
}

.hero-text-container {
	padding: 17rem 0 0 0;
  @include mobile {
    padding: 0;
  }
	&--title {
    max-width: 48rem;
    width: 100%;
		font-size: $fontSize-xxl;
		font-weight: 700;
		line-height: 5.4rem;
		color: $text-dark;
    @include mobile {
      display: none;
    }
	}
	&--description {
		min-height: 17.1rem;
		max-width: 48rem;
		width: 100%;
		margin-top: 2.4rem;
		font-size: $fontSize-ml;
    font-weight: 300;
		line-height: 3.4rem;
    @include mobile {
      max-width: 32.7rem;
      min-height: 12rem;
      width: 100%;
      font-size: $fontSize-m;
      font-style: normal;
      line-height: 2.4rem;
    }
	}
}

.hero-background {
	padding: 7.5rem 0 0 0;
  @include mobile {
    padding: 4rem 0 0 0;
  }
	img {
		height: 56rem;
		max-width: 79.2rem;
		width: 100%;
    @include mobile {
      height: 23.1rem;
    }
	}
}
