@import 'assets/css/common.scss';

.container {
  padding-top: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 20rem;
  line-height: 24px;
}

.navigation {
  position: absolute;
  top: 6rem;
  width: 100%;
  justify-content: space-between;
  display: flex;
}
.navigation-btn {
  width: 3rem;
  height: 3rem;
  &.next {
    right: -1rem;
  }
  &.previous {
    left: -1rem;
  }
}

.practitioner-avatar {
  width: 4.8rem;
  height: 4.8rem;
  object-fit: contain;
  border-radius: 50%;
}

.bold {
  font-weight: 700;
  line-height: 2.4rem;
}

.flex {
  display: flex;
}

.dot-icon {
  margin: 0 0.8rem;
  color: $grey;
}

.name {
  font-size: $fontSize-m;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.specialty-container {
  color: $darkest-grey;
  font-weight: 400;
}

.review-container {
  margin-top: 0.2rem;
  justify-content: center;
  align-items: center;
}

.rating-stars {
  padding-bottom: 0.5rem;
  margin-right: 0.8rem;
}

.average-score {
  margin-right: 0.8rem;
}

.view-availability-btn {
  align-self: center;
  width: 15rem;
  height: 3.2rem;
  margin-top: 0.6rem;
}

.page-number {
  align-self: center;
  margin-top: 1rem;
}

.text-center {
  text-align: center;
}
