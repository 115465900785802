@import 'assets/css/common.scss';

.avatar-container {
  display: flex;
  align-items: center;
  @include flex-column-gap(0.6rem);
  cursor: pointer;
}

.popper {
  z-index: 99999;
}

.drop-down {
  width: 18rem;
  height: 5.1rem;
  box-sizing: border-box;
  background: $white;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  font-weight: 400;
  font-size: $fontSize-m;
  line-height: 2.4rem;
  color: $darkest-grey;
  display: flex;
  padding: 1.5rem 1.8rem;
  font-family: 'Nexa';
  cursor: pointer;
  &:hover {
    color: $magenta;
  }
  &-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
