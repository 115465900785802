@import 'assets/css/common.scss';

.container {
  background-color: $light-grey;
  padding: 2.4rem 2.4rem;
  padding-bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 75rem;
  position: relative;

  @include mobile {
    min-height: 75rem;
    height: auto;
  }
}

.container-iframe {
  background-color: $white;
  font-size: $fontSize-s;
  height: 65rem;

  @include mobile {
    // Exclude powerby section on mobile
    height: calc(100% - 6rem - 3rem);
    margin-bottom: 10rem;
  }
}
