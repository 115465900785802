@import 'assets/css/common.scss';

.container {
  border-radius: 1rem;
  box-sizing: border-box;
  width: 100%;
  max-width: 47.4rem;
  display: flex;
  align-items: center;
  padding: 1.8rem;
  background-color: $light-green;
  color: $darkest-grey;
  font-size: $fontSize-s;
  margin-bottom: 1.7rem;
}

.container-warning {
  background-color: $light-red;
}

.icon {
  align-self: flex-start;
  flex-shrink: 0;
  margin-right: 1.8rem;

  & > path {
    fill: $secondary-green;
  }
  &-warning > path {
    fill: #f5222d;
  }
}
