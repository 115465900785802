@import 'assets/css/common.scss';

.container {
  bottom: 1.3rem;
  max-width: 40rem;
  // min-height: 25.7rem;
  z-index: 9999;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: $box-shadow 0px 4px 8px 4px;
  border-radius: 10px;
  font-size: $fontSize-s;
  color: $darkest-grey;
  padding-left: 3rem;
  padding-right: 2rem;
  padding-top: 1.7rem;
  padding-bottom: 2rem;
  @include mobile {
    margin: 0rem 1rem;
    bottom: 4rem;
  }
}

.practitioner-list {
  display: flex;
  white-space: nowrap;
  overflow: auto;
  scroll-snap-type: x mandatory;
  & > div {
    scroll-snap-align: start;
    flex: 1;
    flex-shrink: 0;
    flex-basis: 100%;
  }
}
