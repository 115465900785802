@import 'assets/css/common.scss';

.has-background-color {
  background-color: $light-grey;
}

.container {
  margin: 1.2rem 0;
  min-height: 6.3rem;
  border-radius: 20px;
  padding: 2.4rem 1.6rem;
  @include mobile {
    flex: 1;
    margin: 0;
    padding: 0;
  }
  .header {
    display: flex;
    .avatar {
      width: 4.8rem;
      height: 4.8rem;
      object-fit: cover;
    }
    .name {
      color: $darkest-grey;
      &-container {
        margin-left: 1.6rem;
        display: flex;
        flex-direction: column;
        font-weight: 700;
        line-height: 2.1rem;
        .star-icon {
          margin: 0 0.2rem;
        }
      }
    }
  }
  .content {
    margin-top: 0.8rem;
    line-height: 2.1rem;
    padding-left: 2.6rem;
    a:-webkit-any-link {
      text-decoration: underline;
      color: $magenta;
      font-weight: 700;
    }
  }
}

.flex {
  display: flex;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.review-source {
  display: flex;
  align-items: center;
  font-weight: 300;
  line-height: 1.8rem;
  margin: 0 0 0.2rem 0;
  .icon {
    margin: 0 0.8rem 0 0;
  }
  @include mobile {
    font-weight: 400;
    font-size: $fontSize-xs;
    line-height: 1.8rem;
    color: $darkest-grey;
  }
}

.created-at {
  font-weight: 300;
  line-height: 1.8rem;
  margin: 0 0 0 1.1rem;
  @include mobile {
    font-weight: 400;
    font-size: $fontSize-xs;
    line-height: 1.8rem;
    color: $darkest-grey;
  }
}

.has-padding {
  padding: 2.4rem;
}
