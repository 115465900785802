@import 'assets/css/common.scss';

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $darkest-grey;
  padding-bottom: 0.6rem;

  border-bottom: 1px solid #cbcbcb;
  span {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.4rem;
  }

  .monthview-button {
    box-shadow: 0px 0px 5px 2px #e5e5e566;
    background-color: $white;
    width: 9.6rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.8rem;
    cursor: pointer;
  }

  .custom-input {
    width: 8rem;
    cursor: pointer;
    display: inline-block;
    text-align: center;
  }
}

.main-section {
  isolation: isolate;
  position: relative;
}

.main-section-iframe {
  height: calc(100% - 100px - 60px); // 100px is for btn group, 60px is for power btn

  @include mobile {
    height: calc(100% - 100px - 60px - 40px); // 100px is for btn group, 60px is for power btn, 40px is for pagination text
  }

  :local(.slot-selection-header) {
    background-color: $white;
  }

  :local(.slot-selection-body) {
    background-color: $white;
    height: calc(100% - 100px);
  }
}

.slot-selection-header {
  display: flex;
  column-gap: 1.9rem;
  background-color: $light-grey;
  overflow: hidden;

  div {
    display: flex;
    flex-basis: 12rem;
    height: 4.8rem;
    flex-shrink: 0;
    gap: 1rem;
    align-items: center;

    @include mobile {
      flex-basis: 11rem;
    }

    img {
      width: 2rem;
      height: 2rem;
      flex-shrink: 0;
      border-radius: 50%;
    }

    span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 700;
    }
  }
}

.slot-selection-body {
  display: flex;
  gap: 1.9rem;
  height: 40rem;

  overflow: hidden auto;

  margin: 0 -1rem;
  margin-bottom: 2rem;
  padding: 1.6rem 1rem;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $light-mid-grey;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-button {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  @include mobile {
    overflow: auto;
    margin-bottom: 0;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    min-width: 12rem;
    flex-basis: 12rem;
    gap: 1.6rem;

    @include mobile {
      min-width: 11rem;
      flex-basis: 11rem;
    }
  }

  button {
    flex-shrink: 0;
    box-shadow: 0px 0px 5px 2px $box-shadow-elevation-09;
    flex-basis: 4rem;
    min-width: 12rem;
    height: 4rem;
    border-radius: 0.8rem;
    background-color: $white;
    cursor: pointer;
    position: relative;
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: $darkest-grey;

    @include mobile {
      min-width: 11rem;
    }

    &:hover {
      background-color: $light-pink;
      border-color: $light-pink;
    }

    &:disabled {
      cursor: initial;
      background: $slot-disabled;
      color: $mid-grey;
    }

    &[data-empty='true'] {
      background-color: transparent;
      border: none;
      box-shadow: none;
      cursor: initial;
    }

    &[data-selected='true'] {
      border: 1px solid $magenta;
      background-color: $slot-selection-active;
      color: $darkest-grey;
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: $magenta;
      color: $white;
      top: -0.9rem;
      right: -0.9rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.8rem;
    }
  }
}

.left {
  position: absolute;
  top: 1.5rem;
  left: -2rem;
  cursor: pointer;
  z-index: 10;
  svg {
    width: 1.6rem;
    height: 1.6rem;

    transform: rotate(-90deg);
  }
}

.right {
  position: absolute;
  top: 1.5rem;
  right: -2rem;
  cursor: pointer;
  z-index: 10;
  svg {
    width: 1.6rem;
    height: 1.6rem;
    transform: rotate(90deg);
  }
}

.container {
  height: 100%;
  margin-top: 4.5rem;

  @include mobile {
    margin-top: 2rem;

    :global(#navigation-controller) {
      position: relative;
      margin-left: -24px;
      width: 100vw;
    }
  }
}

.container-iframe {
  @extend .container;
  height: calc(100% - 60px - 48px); // 48px is for practitioner header

  @include mobile {
    :global(#navigation-controller) {
      position: fixed;
    }
  }
}

.page-location {
  font-weight: 700;

  @include mobile {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}

.blur-bg-bottom {
  width: 100%;
  height: 5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(249, 249, 249, 0.05) 0%,
    rgba(249, 249, 249, 0.9) 65.62%,
    #f9f9f9 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile {
    left: -2.4rem;
    width: 100vw;
  }
}

.blur-bg-bottom-iframe {
  @extend .blur-bg-bottom;
  background: linear-gradient(
    180deg,
    hsla(0, 33%, 99%, 0.42),
    #fefefe 42.19%,
    #fff
  );
}

.blur-bg-top {
  width: 100%;
  height: 5rem;
  position: absolute;
  z-index: 1;
  top: 4.8rem;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    $light-grey 0%,
    rgba(249, 249, 249, 0) 100%
  );
  @include mobile {
    left: -2.4rem;
    width: 100vw;
  }
}

.blur-bg-top-iframe {
  @extend .blur-bg-top;
  background: linear-gradient(
    180deg,
    hsla(0, 33%, 99%, 0.42),
    #fefefe 42.19%,
    #fff
  );
}

.animate-icon {
  animation: move-up-and-down 500ms infinite alternate;
  transition: all 300ms ease;
  opacity: 1;
  visibility: visible;
}

.animate-out {
  opacity: 0;
  visibility: hidden;
}

@keyframes move-up-and-down {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(0.3rem);
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44.6rem;
}
