@import 'assets/css/common.scss';

.container {
  // height: 8rem;
  display: flex;
  align-items: center;
  @include mobile {
    height: 8rem;
  }
  .title {
    padding: 0.2rem 0 0.5rem 0.6rem;
    font-family: 'Nexa';
    font-size: $fontSize-s;
    font-weight: 700;
    display: flex;
    text-transform: capitalize;
    .asterix {
      color: red;
      margin-left: 0.6rem;
    }
  }

  .wrapper {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
    @include mobile {
      flex: 1;
    }
  }

  .icon {
    margin-left: 1.6rem;
    width: 1.5rem;
    height: 2rem;
    &[color='#b118b0'] {
      ellipse + path {
        fill: $magenta !important;
      }

      path {
        fill: $magenta !important;
      }
    }
    @include mobile {
      width: 2rem;
      margin-left: 2rem;
    }
  }
}

.input-wrapper {
  width: 38rem;
  @include mobile {
    width: auto;
    padding-right: 2rem;
  }
}

.location-input {
  width: 32rem;
  @include mobile {
    width: auto;
  }
}
