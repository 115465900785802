@import 'assets/css/common.scss';

.container {
  display: flex;
  flex-direction: column;

  .title {
    font-size: $fontSize-m;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .patient-list {
    border-radius: 10px;
    background-color: $white;
    box-shadow: 0px 0px 5px 2px $box-shadow-elevation-07;

    & > div {
      border-bottom: 1px solid #e8e8e8;
    }

    & > div:last-child {
      border-bottom: none;
    }

    @include mobile {
      margin-left: -2.4rem;
      margin-right: -2.4rem;
      & > div {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
      }
    }
  }
}
