@import 'assets/css/common.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  color: $grey;
  font-weight: 700;
  margin-right: 2.3rem;
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  line-height: 2.1rem;
  cursor: pointer;
  &.active {
    color: $magenta;
    border-bottom: 2px solid $magenta;
  }
}

.flex {
  display: flex;
}

.close-icon {
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
}
