@import 'assets/css/common.scss';

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  @include flex-column-gap(2rem);
}

.truncate {
  @include mobile {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.bold {
  font-weight: 700;
}

.practitioner-name-study {
  font-size: $fontSize-mml;
  line-height: 3rem;
  font-size: 1.6rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @include mobile {
    flex: none;
    font-size: $fontSize-ml;
  }
}

.practitioner-specialty {
  font-size: $fontSize-s;
  font-weight: 700;
  line-height: 2.1rem;
  color: $secondary-purple;
  margin: 0.5rem 0;
}
