@import 'assets/css/common.scss';

.container {
  width: 70rem;
  margin: 0 auto;
  box-sizing: border-box;

  &-iframe {
    width: 100%;
    padding: 0 2.8rem;
  }

  @include mobile {
    width: 100%;
    padding: 0 2rem;
  }
}

.otp-form {
  background-color: $white;
  border: 1px solid transparent;
  border-radius: 16px;
  box-shadow: 0px 0px 5px 2px rgba(220, 220, 220, 0.5);
}

.bold {
  font-weight: 700;
}

.title {
  padding: 1.6rem 0 0.8rem 3rem;
  font-size: $fontSize-mml;
  line-height: 3rem;
  border-bottom: 1px solid $lighter-grey;
}

.section {
  margin-top: 1.9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $darkest-grey;
}

.social-section {
  margin-top: 1.9rem;
  display: flex;
  @include flex-column-gap(2.4rem);

  align-items: center;
}

.fill-section {
  margin-top: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @include flex-column-gap(0.8rem);

  &::after,
  &::before {
    content: '';
    border-top: 1px solid $lighter-grey;
    width: 8rem;
    display: inline-block;
  }
}

.form-section {
  margin-top: 2.9rem;
  padding: 0 3rem;
}

.footer-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: $fontSize-s;
  padding-bottom: 4.4rem;

  .link {
    color: $magenta;
    font-weight: bold;
    text-decoration: underline;
    margin-top: 1.6rem;
    margin-bottom: 2.4rem;
    font-size: $fontSize-s;
    cursor: pointer;
  }
}

.popup {
  color: $magenta;
  font-size: $fontSize-s;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.error-container {
  display: flex;
  @include flex-column-gap(0.8rem);

  flex-wrap: wrap;
}

.resend-button {
  color: $magenta;
  margin-top: 0.8rem;
  font-weight: 700;
  font-size: $fontSize-xs;
  line-height: 1.8rem;
  text-decoration: underline;
  cursor: pointer;
  &:disabled {
    color: $mid-grey;
    cursor: not-allowed;
  }
}
