@import 'assets/css/common.scss';
.controller {
  color: $darkest-grey;
  display: flex;
  @include flex-column-gap(1.2rem);

  [role='tooltip'] {
    z-index: 1;
  }

  @include mobile {
    background-color: $white;
    width: 100%;
    border-radius: 1rem;
    border: 1px solid rgba(249, 249, 249, 1);
    box-shadow: 0px 0px 5px 2px rgba(220, 220, 220, 0.5);
    margin-top: 1rem;
    margin-bottom: 1.6rem;
    & > :first-child {
      width: 100%;
    }
  }

  &-view {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.8rem;
    padding: 0 8px;
    height: 2.4rem;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    cursor: pointer;
    @include flex-column-gap(0.6rem);

    &:hover {
      background-color: $white;
      box-shadow: 0px 0px 5px 2px $box-shadow-elevation-07;
    }

    &[data-active='true'] {
      border: 1px solid $magenta;
      box-shadow: 0px 0px 5px 2px $box-shadow-elevation-10-hover;
      color: $magenta;
      svg g path {
        fill: $magenta;
      }
    }
    &-filter-icon {
      width: 2rem;
      height: 2rem;
    }
    &-icon {
      width: 1rem;
      height: 1rem;
      transform: rotate(180deg);
      > path {
        fill: $magenta;
      }
      @include mobile {
        margin-left: auto;
        width: 1.6rem;
        height: 1.6rem;
        > path {
          fill: $darkest-grey;
        }
      }
    }
    span {
      @include mobile {
        line-height: 2.1rem;
        font-size: 1.4rem;
      }
    }
    @include mobile {
      width: 100%;
      height: 4rem;
      display: flex;
      justify-content: start;
      padding: 0 1.6rem;
    }
  }
  &-dropdown {
    border-radius: 1rem;
    background-color: $white;
    box-shadow: 0px 0px 5px 2px $box-shadow-elevation-07;

    padding: 1.6rem;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include mobile {
      width: calc(100dvw - 4.8rem);
    }

    @include flex-row-gap(1.6rem);

    &-item {
      font-size: 1.4rem;
      line-height: 2.1rem;
      cursor: pointer;

      &[data-active='true'] {
        color: $magenta;
      }
      &:hover {
        color: $magenta;
      }
    }
  }
}
