@import 'assets/css/common.scss';

.container {
  background-color: $white;
  border-radius: 1.6rem;
  padding: 3rem 0;
  display: flex;
  position: relative;
  border: 1px solid transparent;
  box-shadow: 0px 0px 5px 2px $smaller-btn-box-shadow;
  @include mobile {
    flex-direction: column;
    padding: 2rem 0;
  }
}

.container-poc {
  padding-top: 4.5rem;
}

.point-of-contact {
  position: absolute;
  top: 15px;
  left: 20px;
  color: $light-dark;
  font-size: $fontSize-xxs;
  font-weight: 700;
}

.patient-dob-text {
  color: $darkest-grey;
  font-size: $fontSize-s;
  font-weight: 400;

  @include mobile {
    text-align: right;
    flex: 1;
    margin-right: 2rem;
    align-self: flex-start;
  }
}

.patient-dob {
  position: absolute;
  top: 15px;
  right: 20px;
}


.avatar {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: $magenta;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile {
    width: 4rem;
    height: 4rem;
    align-self: flex-start;
  }
}

.left-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include flex-row-gap(0.8rem);
  border-right: 4px solid $lighter-grey;
  flex: 1;
  max-width: 18rem;
  width: 100%;
  min-width: 18rem;

  @include mobile {
    flex-direction: row;
    padding-left: 2rem;
    justify-content: flex-start;
    max-width: none;
    @include flex-column-gap(1.2rem);
    @include flex-row-gap(0rem);

    border-right: none;
  }

  &-iframe {
    flex: none;
    flex-basis: 17rem;

    @include mobile {
      flex-basis: auto;
    }
  }
}

.full-name {
  font-weight: 700;
  font-size: $fontSize-ml;
  line-height: 2.7rem;
  color: $darkest-grey;
  text-align: center;
  word-break: break-word;
  max-width: 150px;

  @include mobile {
    text-align: left;
    margin-top: 0;
    max-width: 150px;
  }
}

.full-width {
  max-width: none;
}

.right-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4rem;
  margin: auto 0;
  padding-left: 3rem;
  padding-right: 3rem;

  & > div {
    margin-top: 1rem; 
  }

  @include mobile {
    padding-left: 2rem;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;

    & > div {
      margin-top: 1rem; 
    }
  }
}

.user-info {
  display: flex;
  flex-direction: row;

  color: $neutral-title;

  .user-detail {
    font-weight: 700;
    font-size: $fontSize-s;
    line-height: 2.1rem;
    white-space: nowrap;
  }
  .user-data {
    font-weight: 400;
    font-size: $fontSize-s;
    line-height: 21px;
    margin-left: 0.8rem;
  }

  @include mobile {
    display: flex;
    @include flex-column-gap(0.8rem);
  }
}

.incomplete-appt {
  color: $light-dark;
  font-weight: bold;
  font-size: $fontSize-xxs;
  grid-column: 1 / span 2;
}