@import 'assets/css/common.scss';

.container {
  background-color: $light-grey;
  font-family: 'Nexa';
  min-height: 100dvh;
}

.header {
  @include mobile {
    position: fixed;
    width: 100%;
    z-index: 100;
  }
}

.content-container {
  padding: 3rem 0 11rem 0;
  display: flex;
  flex-direction: column;
  font-size: $fontSize-s;
  line-height: 2.1rem;

  @include mobile {
    width: auto;
    padding: 6.4rem 0 10rem 0;
  }
}
