@import 'assets/css/common.scss';

.container {
  :global(.MuiOutlinedInput-notchedOutline) {
    border-radius: 10px !important;
  }
}

.title {
  font-size: $fontSize-m;
  font-weight: 700;
  line-height: 2.4rem;
  margin-bottom: 0.8rem;
}
