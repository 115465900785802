@import 'assets/css/common.scss';

:global {
  #month-view-search {
    .fc-dayGridMonth-view {
      background-color: $white;
    }

    .calendar-header {
      font-family: 'Nexa';
      font-size: $fontSize-s;
      color: $darkest-grey;
      font-weight: normal;

      &.fc-col-header-cell {
        border: none;
        height: 32px;
      }
    }

    .calendar-header-detail-page {
      @extend .calendar-header;
      background-color: $light-grey;
    }

    .calendar-cell {
      font-family: 'Nexa';

      .fc-daygrid-day-events {
        min-height: 0 !important;
      }

      &.fc-daygrid-day {
        border: none;

        &.fc-day-past {
          .fc-daygrid-day-frame {
            background-color: $white;
          }

          div {
            color: $mid-grey;
          }
        }

        &.fc-day-today,
        &.fc-day-future {
          :local(.dayNumber) {
            color: $darkest-grey;
          }

          &:hover {
            .fc-daygrid-day-frame {
              opacity: 1;
              background: transparent;
              border: 1px solid $magenta !important;
              border-radius: 10px;

              @include mobile {
                border: none !important;
              }
            }
          }

          &:active {
            .fc-daygrid-day-frame {
              opacity: 1;
              background: transparent;
              border: 1px solid $magenta !important;
              border-radius: 10px;
            }
          }

          &.active-cell {
            opacity: 1;
            background: $primary-gradient;

            div,
            span {
              color: $white !important;
            }
          }

          & .fc-daygrid-day-frame:has([data-active='true']) {
            opacity: 1;
            background: transparent;
            border: 1px solid $magenta !important;
            border-radius: 10px;
          }
          & .fc-daygrid-day-frame:has([data-loading='true']),
          & .fc-daygrid-day-frame:has([data-empty='true']) {
            pointer-events: none;
            border: none !important;
          }
        }

        &.fc-day-today,
        &.fc-day-future {
          background-color: $white;

          &.fc-day-other {
            .fc-daygrid-day-top {
              opacity: 1;

              :local(.dayNumber) {
                color: $mid-grey;
              }
            }
          }
        }
      }

      .fc-daygrid-day-frame {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 5px;
        min-width: 29px;
        height: 70px;
        @include mobile {
          min-width: 0;
          width: auto;
        }
      }
    }

    &[data-highlight-current-date='true']
      .calendar-cell.fc-daygrid-day.fc-day-today {
      background-color: $highlight-day-cell;
      border-radius: 10px;
    }
  }
}

.wrapper {
  margin-top: 16px;
  width: 100%;

  table,
  th,
  td {
    border: none !important;
  }
}

.show-more-button-wrapper {
  text-align: center;
  margin-top: 1rem;

  .show-more-button {
    width: 100%;
    max-width: 15rem;
    margin: 0 auto;
    height: 3.2rem;
  }
}
