.month-view-wrapper {
  text-align: center;
  .view-more-btn {
    margin-top: 1rem;
    width: 15rem;
    height: 3.2rem;
  }
}

.card-loading {
  width: 100%;
  text-align: center;
  margin-top: 3rem;

  .circular-loader {
    margin: 0 auto;
  }
}