@import 'assets/css/common.scss';

.content {
 color: $darkest-grey;
 max-width: 250px;
 font-weight: 400 !important;
}


.tooltip {
  background-color: $white !important;
  border: 1px solid $lighter-grey !important;
  border-radius: 8px !important;
  height: auto;
  line-height: 1.5;
  padding: 8px !important;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
