@import 'assets/css/common.scss';

.container {
  width: 70rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background: $white;
  color: $darkest-grey;

  border: 1px solid $lighter-grey;
  border-radius: 1.6rem;
  @include mobile {
    width: calc(100vw - 1.6rem);
    margin-top: 2rem;
  }
}

.header {
  font-weight: 700;
  font-size: $fontSize-mml;
  line-height: 30px;
  color: $darkest-grey;
  font-family: 'Nexa';
  padding: 1.6rem 0 0.8rem 3rem;
  border-bottom: 1px solid $lighter-grey;
}

.description {
  font-size: $fontSize-s;
  font-weight: 400;
  margin-bottom: 2rem;
  line-height: 21px;
}

.magenta-text {
  color: $magenta;
}

.body {
  padding: 0 3rem;
  padding-top: 2.4rem;
  display: flex;
  flex-direction: column;

  &-group {
    display: flex;
    @include flex-column-gap(5rem);
    @include mobile {
      flex-direction: column;
    }
    & > * {
      flex: 1;
    }
  }
}

.footer {
  width: 24rem;
  margin: 0 auto;
  margin-bottom: 4.3rem;
  font-weight: 400;
  font-size: $fontSize-s;
  line-height: 2.1rem;
  text-align: center;
  color: $darkest-grey;

  @include mobile {
    margin-bottom: 2rem;
  }

  .submit-button {
    margin-top: 2.4rem;
    margin-bottom: 1.6rem;
    max-width: 18rem;
    width: 100%;

    @include mobile {
      width: 100%;
      height: 4rem;
      margin-top: 1.1rem;
    }
  }
}

.btn-link {
  color: $magenta;
  font-weight: bold;
  line-height: 2.1rem;
  margin-left: 0.3rem;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  padding: 0;
  background: transparent;
  font-family: 'Nexa';
  font-size: $fontSize-s;
  margin-top: 0.9rem;
  margin-bottom: 0.9rem;
}
