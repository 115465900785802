@import 'assets/css/common.scss';

.container {
  --main-header-height: 11rem;
  --switch-tab-bar-height: 6.4rem;
  --date-slider-height: 4.8rem;
  --filter-list-height: 5.6rem;
  --content-spacing-height: 10rem;
  overflow-y: auto;
  margin: 0 -2rem;
  padding: 2rem;
  max-height: calc(
    100vh -
      (
        var(--main-header-height) + var(--switch-tab-bar-height) +
          var(--date-slider-height) + var(--filter-list-height) +
          var(--content-spacing-height)
      )
  );
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $light-mid-grey;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-button {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
  @include mobile {
    height: auto;
    max-height: none;
  }
}

.section {
  min-height: 13rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 0.9rem;

    h3 {
      color: $darkest-grey;
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 2.7rem;
    }
    button {
      font-size: 1.4rem;
      line-height: 2.1rem;
      color: $magenta;
    }
  }
}
