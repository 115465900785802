@import 'assets/css/common.scss';

.list {
  width: 90rem;
  padding-bottom: 2rem;
  @include mobile {
    width: 100vw;
  }
}

.content-container {
  margin-top: 24.6rem;
  background-color: $white;
  padding-left: calc((100% - 128rem) / 2);

  @include mobile {
    padding-left: 0;
    margin-top: 16rem;
  }
  transition: all 0.3s;
}

.top-shift {
  transform: translateY(4.7rem);
  @include mobile {
    transform: translateY(6rem);
  }
}

.no-result-container {
  margin-top: 19rem;
  padding-left: calc((100% - 128rem) / 2);

  .no-result-text {
    font-size: $fontSize-m;

    strong {
      font-size: $fontSize-mml;
    }
  }

  @include mobile {
    margin-top: 12.8rem;
    padding: 0 2.4rem;
  }
}
