@import 'assets/css/common.scss';

.headerWrapper {
  font-family: 'Nexa';
  font-weight: bold;
  font-size: 16px;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  color: $second-darkest-grey;
}

.headerNavigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

%arrowIcon {
  background: transparent;
  border: none;
  width: 2.4rem;
  height: 2.4rem;

  margin-left: 2px;
  cursor: pointer;
}

.leftArrowIcon {
  @extend %arrowIcon;

  path {
    fill: $darkest-grey;
  }

  &:disabled {
    path {
      fill: $grey;
    }
  }
}

.rightArrowIcon {
  @extend %arrowIcon;

  path {
    fill: $darkest-grey;
  }
}

