@import 'assets/css/common.scss';

.container {
  position: relative;
  width: auto;
  font-size: $fontSize-s;
  font-family: 'Nexa';
  overflow: hidden;
  @include mobile {
    width: 100%;
    min-height: 100%;
    padding: 0;
  }
}

.close-icon {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
  top: 1.9rem;
  right: 2.1rem;
}
