@import 'assets/css/common.scss';

.container {
  width: 82rem;
  font-size: $fontSize-s;
  line-height: 2.1rem;
  margin: 0 auto 1.6rem auto;
  background-color: $white;
  border: 1px solid $lighter-grey;
  border-radius: 16px;
  margin-top: 2.4rem;
  box-sizing: border-box;

  &-iframe {
    width: 100%;
    padding: 0 2.8rem;
    border: none;
    margin-top: 10rem;
  }

  @include mobile {
    width: 100%;
    border: none;
    border-radius: 0;
    margin-top: 10.4rem;
    background-color: transparent;
    padding: 0 2.4rem;
  }
}

.wrapper {
  padding-top: 4rem;
  padding-bottom: 3.8rem;
  color: $darkest-grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 350px;

  @include mobile {
    padding: 4rem 0;
    background-color: $white;
    box-shadow: 0px 0px 5px 2px rgba(230, 230, 230, 0.4);
    border-radius: 1rem;
    max-width: none;
  }
}

.title {
  font-weight: bold;
  font-size: 16px;
  margin-top: 24px;
  text-align: center;
  @include mobile {
    width: 60%;
  }
}

.description {
  font-size: 14px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 24px;
  padding: 0 1rem;
}

.container-link {
  display: flex;
  flex-direction: column;
  @include flex-row-gap(1.7rem);
  align-items: center;
}

.btn {
  @include mobile {
    min-width: 15rem;
    height: auto;
    padding: 1rem 4rem 0.9rem 4rem;  
  }
}

.btn-email {
  width: 25rem;
}

.link-home {
  font-weight: 700;
  text-decoration: underline !important;
  color: $magenta !important;
}
