@import 'assets/css/common.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: 0 0 2.4rem 0;

  @include mobile {
    margin-top: 4rem;
    padding-bottom: 0;
  }
}
