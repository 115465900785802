@import 'assets/css/common.scss';

.container {
  font-size: $fontSize-s;
  line-height: 2.1rem;
  margin: 3.6rem 0 2.4rem 0;

  @include mobile {
    margin: 0;
    margin-top: 4rem;
    padding: 0 2.4rem;
  }

  .description {
    margin-bottom: 2rem;
    white-space: pre-line;

    a:-webkit-any-link {
      text-decoration: underline;
      color: $magenta;
      font-weight: 700;
    }
    &-title {
      font-weight: 700;
      line-height: 2.4rem;
      margin-bottom: 0.8rem;
      font-size: $fontSize-mml;
      color: $secondary-purple;
      line-height: 3rem;
    }
  }
}

.services {
  columns: 2 auto;
  @include mobile {
    columns: 1;
  }
  &-title {
    font-size: $fontSize-m;
    font-weight: 700;
    margin: 0 0 2rem 0;
  }
  &-item {
    margin: 0 0 0.8rem 0;
  }
  &-item-value {
    margin: 0 0 0 1rem;
  }
}
