@import 'assets/css/common.scss';

.container {
  width: 93.6rem;
  height: 30.6rem;
  background-color: $white;
  box-shadow: 0px 0px 5px 2px $box-shadow-elevation-07;
  display: flex;
  padding-left: 2.3rem;
  padding-top: 2.4rem;
  border-radius: 1rem;
  border: 0.5px solid transparent;
  color: $darkest-grey;

  &:hover {
    box-shadow: 0px 0px 5px 2px rgba(176, 27, 174, 0.2);
    border: 0.5px solid $magenta;
    cursor: pointer;

    @include mobile {
      border: none;
      box-shadow: 0px 0px 5px 2px $box-shadow-elevation-07;
    }
  }

  &:active {
    box-shadow: 0px 0px 5px 2px rgba(176, 27, 174, 0.2);
    border: 0.5px solid $magenta;
    cursor: pointer;
  }

  @include mobile {
    width: 100vw;
    flex-direction: column;
    height: auto;
    min-height: 42rem;
    padding: 0 2.1rem;
    padding-top: 2.4rem;
    @include flex-row-gap(3rem);
  }
}

.main-content {
  margin-left: 4rem;
  flex-basis: 48rem;
  font-size: 1.4rem;
  line-height: 2.1rem;
  position: relative;
  &-title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 3rem;
    max-width: fit-content;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &:hover {
      text-decoration: underline;
    }
    @include mobile {
      display: none;
    }
  }
  @include mobile {
    margin-left: 0;
    flex-basis: auto;
  }
}

.description {
  margin-left: 3.4rem;
  @include mobile {
    display: none;
  }
}

.card-self {
  @include mobile {
    align-self: flex-start;
    flex-shrink: 0;
    margin-right: 1.6rem;
  }
}

.header {
  @include mobile {
    display: flex;
  }
}

.detail {
  display: none;
  @include mobile {
    display: block;
    font-size: 1.4rem;
    line-height: 2.1rem;
    &-title {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.7rem;
    }
    &-info {
      display: flex;
      align-items: center;
    }
    .distance {
      font-weight: 700;
      margin-left: 6.8px;
    }
    .icon {
      margin-left: 2rem;
    }
  }
}
