@import 'assets/css/common.scss';

.bold {
  font-weight: 700;
}

.resend-btn {
  text-decoration: underline;
  color: $magenta;

  &:hover {
    cursor: pointer;
  }
  &.disabled {
    color: $grey;
    &:hover {
      cursor: default;
    }
  }
  &-container {
    margin-top: 0.8rem;
  }
}

.container {
  display: flex;
  margin-top: 0.8rem;
  @include flex-column-gap(0.9rem);

  @include mobile {
    flex-direction: column;
    @include flex-column-gap(0rem);
  }

  &-iframe {
    width: max-content;
  }
}

.fixed-width {
  width: 4.1rem;
  margin-right: 0.2rem;
  display: inline-block;
}
