@import 'assets/css/common.scss';

.container {
  display: flex;
  align-items: center;
}

.circle {
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.8rem;
  border-radius: 100%;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $mid-grey;
  font-size: $fontSize-s;
  font-weight: 700;
  &.highlighted {
    background-color: $magenta;
  }
}

.content {
  color: $darkest-grey;
  font-size: $fontSize-s;
  @include mobile {
    display: none;
  }
}

.mobileContent {
  color: $darkest-grey;
  font-size: $fontSize-xxs;
  display: none;
  @include mobile {
    display: block;
  }
}

.dash {
  margin: 0 0.8rem;
}
