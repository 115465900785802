@import 'assets/css/common.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;

  @include mobile {
    padding: 1rem 0;
  }

  .reason-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;

    .reason-container {
      position: relative;
      width: 100%;
      min-width: 30rem;
      @include mobile {
        width: auto;
        min-width: 16rem;
      }
    }

    .close-icon > svg {
      cursor: pointer;
      margin-top: 5px;
      width: 16px;
      height: 16px;

      path {
        fill: $second-lightest-grey;
      }
    }
  }
}

.unavailable-service {
  margin: 16px;
  margin-bottom: 8px;
  border-top: 1px solid $lighter-grey;
  padding-top: 16px;
  font-size: $fontSize-s;
  color: $mid-grey;
  font-weight: bold;
  display: flex;
  flex-direction: row;

  span {
    margin-right: 8px;
  }
}

.warning {
  position: absolute;
  right: 50px;
  top: 15px;
}

.schedule-btn {
  width: 12rem;
  height: 4rem;
}
