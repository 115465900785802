@import 'assets/css/common.scss';

.container {
  width: 82rem;
  font-size: $fontSize-s;
  line-height: 2.1rem;
  margin: 0 auto 1.6rem auto;

  &-iframe {
    width: 100%;
    padding-top: 1.6rem;
  }

  @include mobile {
    width: 100%;
    border: none;
    border-radius: 0;
    margin-bottom: 0.6rem;
  }
}

.wrapper {
  padding-bottom: 1.6rem;
  color: $darkest-grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mobile {
    padding: 1.6rem 2.4rem;
  }
}

.firstSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  @include mobile {
    margin-bottom: 0.4rem;
  }

  img {
    width: 42px;
    height: 42px;
    border-radius: 100%;
    margin-bottom: 10px;
    object-fit: cover;

    @include mobile {
      margin-bottom: 0.4rem;
    }
  }

  .mainTitle {
    font-weight: bold;
    font-size: $fontSize-mml;
    text-align: center;
    margin-bottom: 3.2rem;
    @include mobile {
      font-size: $fontSize-m;
      margin-bottom: 1.6rem;
    }
  }

  .title {
    font-weight: bold;
    font-size: $fontSize-mml;
    text-align: center;
    line-height: 24px;
    @include mobile {
      font-size: $fontSize-m;
    }
  }
}

.bookingDateTime {
  font-weight: $fontSize-s;
  @include mobile {
    font-size: $fontSize-xs;
  }
}

.info {
  display: flex;
  font-weight: $fontSize-s;
  margin-bottom: 5px;
  @include flex-column-gap(0.5rem);
  @include mobile {
    font-size: $fontSize-xs;
  }
}

.infoWrapper {
  @include mobile {
    display: none;
  }
}

.mobileInfoWrapper {
  display: none;
  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
