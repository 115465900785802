/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Airbnb Cereal App Book';
  font-style: normal;
  font-weight: normal;
  src: local('Airbnb Cereal App Book'),
    url('./assets/fonts/AirbnbCereal/AirbnbCerealBook.woff') format('woff');
}

@font-face {
  font-family: 'Airbnb Cereal App Light';
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.02em;
  src: local('Airbnb Cereal App Light'),
    url('./assets/fonts/AirbnbCereal/AirbnbCerealLight.woff') format('woff');
}

@font-face {
  font-family: 'Airbnb Cereal App Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Airbnb Cereal App Medium'),
    url('./assets/fonts/AirbnbCereal/AirbnbCerealMedium.woff') format('woff');
}

@font-face {
  font-family: 'Airbnb Cereal App Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Airbnb Cereal App Bold'),
    url('./assets/fonts/AirbnbCereal/AirbnbCerealBold.woff') format('woff');
}

@font-face {
  font-family: 'Airbnb Cereal App Extra Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Airbnb Cereal App Extra Bold'),
    url('./assets/fonts/AirbnbCereal/AirbnbCerealExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Airbnb Cereal App Black';
  font-style: normal;
  font-weight: bold;
  src: local('Airbnb Cereal App Black'),
    url('./assets/fonts/AirbnbCereal/AirbnbCerealBlack.woff') format('woff');
}

@font-face {
  font-family: 'Nexa';
  font-style: normal;
  font-weight: 700;
  src: local('Nexa'), url('./assets/fonts/Nexa/Nexa-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Nexa';
  font-style: normal;
  font-weight: 300;
  src: local('Nexa'), url('./assets/fonts/Nexa/Nexa-Light.woff') format('woff');
}

.App {
  font-family: 'Nexa';
  // height: 100%;
  a {
    text-decoration: none;
    &:active,
    &:visited {
      color: unset;
    }
    &:-webkit-any-link {
      color: unset;
    }
  }
}
h1,
h2,
h3,
h4 {
  padding: 0;
  margin: 0;
}
