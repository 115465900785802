@import 'assets/css/common.scss';

.container {
  flex: 3;
  padding: 0 3rem 0 2rem;
}

.section {
  display: flex;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  line-height: 2.1rem;
}

.clinicName {
  font-size: $fontSize-mml;
  line-height: 3rem;
}

.icon {
  margin-right: 0.8rem;
  width: 1.6rem;
  height: 1.6rem;
  flex-shrink: 0;
}

.bold {
  font-weight: 700;
}

.location-container {
  display: flex;

  .mobile-view {
    display: flex;
    align-items: center;
    @include flex-column-gap(0.8rem);

    > :first-child {
      margin-left: 0.8rem;
    }

    > :nth-child(2) {
      font-weight: 700;
      font-size: $fontSize-s;
      line-height: 2.1rem;
      color: $darkest-grey;
    }
  }

  .desktop-view {
    margin: 0 0.5rem;
    display: flex;
    @include flex-column-gap(0.5rem);
  }
}
