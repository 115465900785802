$magenta: #b01bae;
$primary-purple: #b118b0;
$primary-purple-lighter: #c138c0;
$primary-purple-lightest: #d16ad0;
$primary-purple-darker: #8e058d;
$primary-purple-darkest: #6b046a;
$secondary-purple: #51169d;
$white: #ffff;
$light-grey: #f9f9f9;
$lighter-grey: #e8e8e8;
$mid-grey: #a9a9a9;
$light-mid-grey: #d9d9d9;
$hover-grey: #0000000a;
$grey: #c2c1c1;
$lightest-grey: #e2e2e2;
$second-lightest-grey: #7c7c7c;
$time-slot-grey: #eeecee;
$darkest-grey: #373737;
$second-darkest-grey: #737373;
$text-dark: #3d3d3d;
$light-dark: #727272;
$light-pink: #f3dff7;
$box-shadow: rgb(99 99 99 / 20%);
$light-box-shadow: rgba(203, 203, 203, 0.2);
$smaller-btn-box-shadow: rgba(230, 230, 230, 0.4);
$navigation-bottom-box-shadow: rgba(216, 216, 216, 0.5);
$box-shadow-elevation-07: rgba(220, 220, 220, 0.5);
$box-shadow-elevation-08: rgba(203, 203, 203, 0.4);
$box-shadow-elevation-10-hover: rgba(176, 27, 174, 0.1);
$box-shadow-elevation-09: rgba(229, 229, 229, 0.4);
$secondary-red: #f5222d;
$neutral-title: #15232b;
$secondary-green: #1a9901;
$light-green: #ebf8e4;
$light-red: #fff1f0;
$highlight-day-cell: rgba(247, 237, 247, 1);
$active-filter: #f7edf7;
$slot-selection-active: #fff6ff;
$slot-disabled: #f0f0f0;
$modal-border-color: rgba(232, 232, 232, 1);

$primary-gradient: linear-gradient(206.28deg, #b01bae 0%, #da3f85 100%);

$fontSize-smaller-xxs: 0.8rem;
$fontSize-medium-xxs: 1rem;
$fontSize-xxs: 1.1rem;
$fontSize-xs: 1.2rem;
$fontSize-medium-s: 1.3rem;
$fontSize-s: 1.4rem;
$fontSize-m: 1.6rem;
$fontSize-ml: 1.8rem;
$fontSize-mml: 2rem;
$fontSize-l: 2.4rem;
$fontSize-xl: 3rem;
$fontSize-xxl: 3.6rem;

$mobile-width: 500px;

@mixin mobile {
  @media (max-width: $mobile-width) {
    @content;
  }
}

@mixin flex-column-gap($value) {
  & > * + * {
    margin-left: $value;
  }
}

@mixin flex-row-gap($value) {
  & > * + * {
    margin-top: $value;
  }
}
