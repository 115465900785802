@import 'assets/css/common.scss';

.modal {
  width: 100%;
  max-width: 48rem;
  color: $darkest-grey;

  .container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }
  .title {
    font-size: 2rem;
    line-height: 3rem;
  }

  .info {
    margin-top: 1.6rem;
    margin-bottom: 2.4rem;
  }

  .btn {
    width: 100%;
    margin-bottom: 1.6rem;
  }

  .cancel-btn {
    width: 100%;
    max-width: 15rem;
  }

  button {
    align-self: center;
  }
}
