.main-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;

  transition-duration: 0.5s;
  transition-property: transform;

  &.animate-top {
    transform: translateY(-22rem);
  }
}
