@import 'assets/css/common.scss';

.inputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  font-family: 'Nexa';
  font-weight: bold;
  font-size: $fontSize-m;
  margin: 0 5px;
  padding-top: 0.3rem;
  cursor: pointer;
  width: 9rem;
  text-align: center;
}

%arrowIcon {
  background: transparent;
  border: none;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.leftArrowBtn {
  @extend %arrowIcon;
  text-align: left;

  path {
    fill: $darkest-grey;
  }

  &:disabled {
    path {
      fill: $grey;
    }
  }
}

.rightArrowBtn {
  @extend %arrowIcon;
  text-align: right;

  path {
    fill: $darkest-grey;
  }
}
