@import 'assets/css/common.scss';

.container {
  background-color: $light-grey;
  padding-left: 2.6rem;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  margin-bottom: 4rem;

  @include mobile {
    margin-bottom: 0;
    padding: 1.6rem;
    text-align: left;
    flex: 1;
  }

  .name {
    font-size: $fontSize-xxl;
    line-height: 5.4rem;
    @include mobile {
      font-size: $fontSize-mml;
      line-height: 3rem;
    }
  }
}

.rating-container {
  display: flex;
  flex-direction: column;
}

.contact-infos {
  display: flex;
  padding: 0.8rem 0 0.6rem 0;
  @include flex-column-gap(0.8rem);

  &.hide-on-desktop {
    display: none;
    @include mobile {
      display: flex;
      align-items: center;
      padding: 0.8rem 0 0.6rem 0;
    }
  }
  a:-webkit-any-link.contact-infos--value {
    text-decoration: underline;
    color: $magenta;
  }
}

.flex-row {
  display: flex;
}

.inline-block {
  display: inline-block;
}

.bold {
  font-weight: 700;
}

.secondary-purple {
  color: $secondary-purple;
  margin-bottom: 0.8rem;
}

.expand-icon {
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 1.1rem;
  transform: rotate(180deg);
  cursor: pointer;
  &.expanded {
    transform: rotate(0);
  }
}

.grid {
  display: grid;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  grid-template-columns: minmax(8.1rem, 1fr) 2fr;
}

.icon {
  flex-shrink: 0;
}
