@import 'assets/css/common.scss';

.container {
  font-size: $fontSize-s;
  line-height: 2.1rem;
  text-align: center;
  padding: 0 0 2.4rem 0;
  @include mobile {
    position: relative;
  }
}

.booking-button {
  width: 29.9rem;
  @include mobile {
    display: none;
  }
  &-wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}

.responsive-booking-button {
  width: 32.7rem;
  &-container {
    display: none;
    position: fixed;
    bottom: 0;
    height: 7.2rem;
    width: 100%;
    background-color: white;
    border-top: 1px solid $lighter-grey;
    z-index: 900;
    @include mobile {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.wrapper {
  display: flex;
  gap: 3rem;
  text-align: left;
  @include mobile {
    flex-direction: column;
  }
  &-left {
    min-width: 750px;
    flex: 0.6;
    @include mobile {
      min-width: 375px;
    }
  }
  &-right {
    flex: 0.4;
  }
}

.divider {
  margin: 0 0 2.4rem 0;
}

.loading {
  align-self: center;
  margin: 3rem 0 0 0;
}

.back-to-top {
  position: fixed;
  right: 55rem;
  bottom: 4rem;
  @include mobile {
    display: none;
  }
}
