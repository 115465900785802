@import 'assets/css/common.scss';

.option-container {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 1.6rem;
  width: 2.4rem;
  height: 2.4rem;
  color: $mid-grey;
}

.content-container {
  flex: 1
}

.bold {
  font-weight: 300;
  color: $darkest-grey;
}

.flex-align-center {
  display: flex;
  justify-content: center;
}

.search-messages-container {
  display: flex;
  align-items: center;
  color: $mid-grey;
}

.no-results-divider {
  margin: 1.6rem 0 1.75rem 0;
}

.exist-results-divider {
  margin: 0 0 1.75rem 0;
}

.magnify-icon {
  width: 2.4rem;
  height: 2.4rem;
  margin: 0 0.8rem 0 0;
}