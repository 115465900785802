@import 'assets/css/common.scss';

.container {
  position: relative;
  display: flex;
  align-items: center;
  width: 128rem;
  height: 8rem;
  margin: 1.5rem auto 0;
  border-radius: 4rem;
  background-color: #ffff;
  border: 1px solid transparent;
  @include mobile {
    flex-direction: column;
    width: 100%;
    align-items: unset;
    height: unset;
    padding-bottom: 2.3rem;
  }
  .container-search-field {
    display: flex;
    align-items: center;
    @include mobile {
      flex-direction: column;
      align-items: unset;
    }
    &:first-child {
      margin-left: 1.03rem;
      @include mobile {
        margin: 0;
      }
    }
  }
  .button {
    position: absolute;
    right: 1rem;
    width: 4.8rem;
    height: 4.8rem;
    @include mobile {
      position: unset;
      align-self: center;
      width: 28.7rem;
      height: 4rem;
    }
    .button-label {
      display: block;
      @include mobile {
        display: none;
      }
    }
    .button-label-mobile {
      display: none;
      @include mobile {
        display: block;
      }
    }
  }
}

.box-shadow-container {
  box-shadow: $box-shadow 0px 2px 8px 0px;
  &:hover {
    border-color: $magenta;
  }
}
