@import 'assets/css/common.scss';

.avatar {
  width: 4rem;
  height: 4rem;
  background-color: $magenta;
  border-radius: 50%;
  font-weight: 700;
  font-size: $fontSize-m;
  line-height: 2.4rem;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile {
    width: 3.6rem;
    height: 3.6rem;
  }
}
