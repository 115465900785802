@import 'assets/css/common.scss';

.container {
  background-color: $light-grey;
  font-family: 'Nexa';
  min-height: 100dvh;
}

.header {
  @include mobile {
    position: fixed;
    width: 100%;
    z-index: 100;
  }
}

.content-container {
  padding: 3rem 0 11rem 0;
  display: flex;
  flex-direction: column;
  font-size: $fontSize-s;
  line-height: 2.1rem;

  @include mobile {
    width: auto;
    padding: 6.4rem 0 10rem 0;
  }
}

.content {
  width: 100%;
  max-width: 70rem;
  font-size: $fontSize-s;
  line-height: 2.1rem;
  margin: 0 auto 1.6rem auto;
  background-color: $white;
  border: 1px solid $lighter-grey;
  border-radius: 16px;
  margin-top: 2.4rem;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 2px rgba(220, 220, 220, 0.5);

  @include mobile {
    width: auto;
    margin: 1.6rem;
  }
}

%section {
  padding: 1.6rem 3rem;

  @include mobile {
    padding: 1.6rem;
  }
}

.headerSection {
  @extend %section;

  border-bottom: 1px solid $lighter-grey;

  .email {
    color: $magenta;
  }

  .title {
    line-height: 30px;
    font-weight: bold;
    font-size: $fontSize-mml;
    margin-bottom: 0.8rem;
  }

  .subtitle {
    @include mobile {
      display: none;
    }
  }
}

.bodySection {
  @extend %section;
}

.guidance {
  background-color: $light-grey;
  padding: 1.7rem 2.4rem;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 2px rgba(220, 220, 220, 0.5);
  font-size: $fontSize-s;
  margin-top: 2.4rem;
  margin-bottom: 1.6em;

  .title {
    color: $darkest-grey;
    font-weight: bold;
  }

  ul {
    margin-top: 0.4rem;
    padding-left: 1rem;
    list-style-type: none;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .errorName {
      margin-left: 9px;
    }

    .success {
      color: $secondary-green;

      svg {
        width: 1rem;
        height: 12px;
      }
    }

    .error {
      color: $secondary-red;

      svg {
        width: 1rem;
        height: 5px;
      }
    }
  }
}

.footerSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 2.4rem;
}
