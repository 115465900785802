@import 'assets/css/common.scss';

.info {
  color: $darkest-grey;
  margin-top: 1rem;
  margin-bottom: 3rem;
  max-width: 44rem;
}
.section {
  margin-top: 1.9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $darkest-grey;
}

.social-section {
  margin-top: 1.9rem;
  display: flex;
  @include flex-column-gap(2.4rem);

  align-items: center;
}

.social-icon {
  border: none;
  background-color: transparent;
  padding: 0 0.3rem;
  cursor: pointer;
}

.fill-section {
  margin-top: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @include flex-column-gap(0.8rem);

  &::after,
  &::before {
    content: '';
    border-top: 1px solid $lighter-grey;
    width: 10rem;
    display: inline-block;
  }
}

.form-section {
  margin-top: 2.9rem;
}

.name-section {
  display: flex;
  @include flex-column-gap(5rem);

  margin-bottom: 4.4rem;
  & > * {
    flex: 1;
  }
  @include mobile {
    flex-direction: column;
    @include flex-column-gap(0);
    margin-bottom: 0;
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 3.2rem;
  .button {
    height: 4rem;
  }
  & > :first-child {
    margin-right: 1.6rem;
  }

  @include mobile {
    justify-content: center;
    margin-top: 3rem;

    .button {
      flex: 1;
    }
  }
}
