@import 'assets/css/common.scss';

.root {
  position: relative;

  :global(.MuiOutlinedInput-notchedOutline) {
    border-radius: 10px !important;
  }
}

.title {
  font-size: $fontSize-m;
  font-weight: 700;
  line-height: 2.4rem;
  margin-bottom: 0.8rem;
}

.unavailable-service {
  margin: 16px;
  margin-bottom: 8px;
  border-top: 1px solid $lighter-grey;
  padding-top: 16px;
  font-size: $fontSize-s;
  color: $mid-grey;
  font-weight: bold;
  display: flex;
  flex-direction: row;

  span {
    margin-right: 8px;
  }
}

.warning-root {
  :global(.MuiAutocomplete-input:first-child) {
    max-width: calc(100% - 20px)
  }
}

.warning {
  position: absolute;
  right: 50px;
  top: 15px;

  @include mobile {
    right: 40px;
  }
}
