@import 'assets/css/common.scss';

.container {
  font-family: 'Nexa';
  margin: 0.8rem 0 0 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .title {
    width: 40%;
  }
  .value {
    width: 60%;
    font-weight: 700;
    word-break: break-word;
  }
}