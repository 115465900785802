@import 'assets/css/common.scss';

.container {
  width: 82rem;
  margin: 0 auto;
  position: relative;

  @include mobile {
    width: 100%;
  }

  &-iframe {
    width: 100%;
    padding: 0 2.8rem;
    @include mobile {
      padding: 0;
    }
  }
}

.patient-list-section {
  margin-bottom: 6rem;

  @include mobile {
    margin-bottom: 4rem;
  }
}

.card-container {
  @include flex-row-gap(1.6rem);
  @include mobile {
    padding: 0 2rem;
    &:last-child {
      margin-bottom: 5rem;
    }
  }
}

.detail-section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @include mobile {
    margin-top: 0;
  }
}

.not-you-section {
  font-size: $fontSize-xs;

  @include mobile {
    margin-top: 1rem;
  }
}

.click-here {
  color: #b01bae;
  font-weight: 700;
  line-height: 2.1rem;
  margin-left: 0.3rem;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  padding: 0;
  background: transparent;
  font-family: 'Nexa';
  font-size: $fontSize-s;
}

.title {
  @include flex-column-gap(1.4rem);

  font-weight: 700;
  font-size: $fontSize-m;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  max-width: 45rem;
  align-items: baseline;

  &-select {
    font-weight: 400;
    font-size: $fontSize-xs;
    line-height: 18px;
    color: $darkest-grey;

    @include mobile {
      margin-left: 0;
      margin-top: 0.5rem;
    }
  }

  @include mobile {
    flex-direction: column;
  }
}

.description-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.6rem;

  @include mobile {
    flex-direction: column;
    padding: 0 2rem;
    margin-bottom: 1rem;
  }
}

.textarea {
  resize: none;
  width: 100%;
  border: 0.5px solid $light-grey;
  box-shadow: 0px 0px 5px 2px #e5e5e566;
  border-radius: 1rem;
  min-height: 10rem;
  font-family: inherit;
  color: $darkest-grey;
  font-size: 1.4rem;
  line-height: 2.1rem;
  &:focus,
  &:hover,
  &:active {
    outline: none;
    border-color: $magenta;
    box-shadow: 0px 0px 5px 2px #b01bae1a;
  }
  padding: 1.3rem 1.6rem;
  &::placeholder {
    color: $grey;
  }
  &-error {
    border-color: $secondary-red;
    box-shadow: 0px 0px 5px 2px #dcdcdc80;
    &:focus,
    &:hover,
    &:active {
      border-color: $secondary-red;
      box-shadow: 0px 0px 5px 2px #dcdcdc80;
    }
  }
}

.note-length {
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-top: 0.8rem;
  margin-bottom: 1.6rem;
  display: flex;
  align-items: center;
  @include flex-column-gap(0.8rem);
  &-error {
    span {
      color: $secondary-red;
    }
  }
}
