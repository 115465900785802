.main-section {
  padding: 2.4rem;
  padding-bottom: 0;
  .header {
    display: flex;
    justify-content: space-between;

    h3 {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 2.7rem; /* 150% */
    }

    button {
      cursor: pointer;
      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  .body {
    padding-top: 3rem;
    padding-bottom: 3.7rem;
  }
}
