@import 'assets/css/common.scss';

.container {
  .active-add-patient {
    cursor: pointer;
    font-size: $fontSize-m;
    font-weight: bold;
    color: $magenta;
    width: fit-content;
  }

  .inactive-add-patient {
    font-size: $fontSize-m;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    svg {
      cursor: pointer;
      margin-top: 5px;
      width: 16px;
      height: 16px;

      path {
        fill: $second-lightest-grey;
      }
    }
  }

  .form {
    display: grid;
    grid-template-columns: auto auto;
    gap: 0.4rem 2rem;
    margin-top: 1.6rem;

    @include mobile {
      grid-template-columns: auto;
    }

    & > div {
      margin-top: 0.8rem;
      margin-bottom: 1.6rem;
    }

    div {
      height: 5.4rem;
    }

    .book-btn {
      grid-column: 2 / 2;
      justify-self: end;
      @include mobile {
        grid-column: 1 / 1;
      }
    }

    :global(.MuiOutlinedInput-notchedOutline) {
      border-radius: 10px !important;
    }
  }
}
