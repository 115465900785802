@import 'assets/css/common.scss';

.container {
  margin: 0 0 1.6rem 0;
  @include mobile {
    padding: 0 2.4rem;
  }
}

.bold {
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  margin-right: 0.8rem;
  @include mobile {
    font-size: $fontSize-m;
    line-height: 2.4rem;
  }
}

.location {
  font-size: 16px;
  @include mobile {
    font-size: 14px;
  }
}
