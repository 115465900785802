@import 'assets/css/common.scss';

.flex {
  display: flex;
}

.bold {
  font-weight: 700;
}

.practitioner-avatar {
  width: 4.8rem;
  height: 4.8rem;
  margin-right: 0.8rem;
  object-fit: cover;
  border-radius: 100%;
  flex-shrink: 0;
}

.section {
  min-height: 4.8rem;
  display: flex;
  align-items: center;
}

.rating-stars {
  padding-bottom: 0.3rem;
  margin-right: 1.4rem;
}

.total-score {
  margin-right: 0.5rem;
}

.dot {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 100%;
  color: $grey;
  margin: 0 0.8rem;
}
