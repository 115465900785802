@import 'assets/css/common.scss';

.upper-section {
  position: relative;
  padding-bottom: 11rem;
}

.content-wrapper {
  width: 128rem;
  margin: 0 auto;
  @include mobile {
    width: 32.7rem;
  }
  .page-title-mobile {
    display: none;
    @include mobile {
      display: block;
      font-size: $fontSize-l;
      line-height: 3.6rem;
      font-weight: 700;
      color: $text-dark;
    }
  }
}

.top-left {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
  @include mobile {
    display: none;
  }
}

.top-right {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  pointer-events: none;
  @include mobile {
    display: none;
  }
}

.bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
  @include mobile {
    display: none;
  }
}

.bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  pointer-events: none;
  @include mobile {
    display: none;
  }
}

.top-left-responsive {
  display: none;
  @include mobile {
    display: unset;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
  }
}

.top-right-responsive {
  display: none;
  @include mobile {
    display: unset;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    pointer-events: none;
  }
}

.bottom-left-responsive {
  display: none;
  @include mobile {
    display: unset;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
  }
}

.bottom-right-responsive {
  display: none;
  @include mobile {
    display: unset;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    pointer-events: none;
  }
}
