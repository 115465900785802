@import 'assets/css/common.scss';

.header {
  @include mobile {
    position: fixed;
    width: 100%;
    z-index: 100;
  }
}

.container {
  background-color: $light-grey;
  font-family: 'Nexa';
  height: 100%;

  &-iframe {
    position: relative;
    background-color: $white;
    height: 100dvh;
  }

  @include mobile {
    background-color: $light-grey;
  }
}

.content-container {
  padding: 3rem 0 0 0;
  display: flex;
  flex-direction: column;
  font-size: $fontSize-s;
  line-height: 2.1rem;
  padding-bottom: calc(80px + 90px);
  background-color: $light-grey;

  @include mobile {
    width: auto;
    padding: 6.4rem 0 10rem 0;
  }

  &-iframe {
    width: auto;
    background-color: $white;
    padding-top: 6rem;

    @include mobile {
      padding-bottom: 15rem;
    }
  }
}

.back-to-top-btn {
  position: fixed;
  right: 4rem;
  bottom: 10rem;
  @include mobile {
    display: none;
  }
}

.review-header {
  font-weight: 700;
  font-size: $fontSize-mml;
  line-height: 3rem;
  text-align: center;
  color: $darkest-grey;
  margin-bottom: 1.6rem;
  @include mobile {
    margin-top: 0.8rem;
  }
}

.remove {
  display: none;
}
