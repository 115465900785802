@import 'assets/css/common.scss';

.container {
  height: 8rem;
}

.group-container {
  display: flex;
  flex-direction: column;

  & > span:nth-child(2) {
    margin-top: 0.6rem;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: $mid-grey;
  }
}
