@import 'assets/css/common.scss';

.search-bar {
  display: flex;
  position: relative;
  align-items: center;
  height: 5.6rem;
  padding: 0 6rem 0 1.5rem;
  border: 1px solid $grey;
  box-sizing: border-box;
  border-radius: 4rem;
  @include mobile {
    padding: 0 0;
    height: 4rem;
    width: 85%;
  }
  &__mini-search-icon {
    @extend .icon;
    margin-left: 0;
    display: none;
    @include mobile {
      display: block;
    }
  }
  &__fields {
    &-content {
      flex: 1;
      margin: 0 0 0 1.2rem;
      align-items: center;
      font-weight: 700;
      font-size: $fontSize-s;
      color: $darkest-grey;
      line-height: 2.1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include mobile {
        margin: 0 1.5rem 0 1rem;
      }
    }
    &-wrapper {
      max-width: 22rem;
      display: flex;
      @include mobile {
        margin-left: 1rem;
        max-width: none;
        &__secondary {
          display: none;
        }
      }
    }
  }
  &__divider-wrapper {
    margin: 0 0 0 3.8rem;
    @include mobile {
      display: none;
    }
  }
  &__icons {
    @extend .icon;
    @include mobile {
      display: none;
    }
  }
  &__search-icon {
    width: 4rem;
    height: 4rem;
    position: absolute;
    right: 0.8rem;
    cursor: pointer;
    @include mobile {
      display: none;
    }
  }
  &__content-on-mobile {
    display: none;
    @include mobile {
      display: flex;
      align-items: center;
      margin-left: 1.4rem;
      .search-bar__mobile-service-content {
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 1.7rem;
      }
      .search-bar__mobile-date-content {
        font-weight: 400;
        font-size: 1.1rem;
        line-height: normal;
      }
    }
  }
}

.icon {
  margin-left: 0.76rem;
  align-items: center;
  width: 1.6rem;
  height: 1.6rem;
  & > ellipse + path {
    fill: $magenta;
  }

  &[color='#b118b0'] {
    path {
      fill: $magenta;
    }
  }
}

.button {
  position: absolute;
  width: 4rem;
  height: 4rem;
  right: 0.8rem;
  @include mobile {
    display: none;
  }

  :global(.MuiButton-label) {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.map-button-wrapper {
  display: none;
  @include mobile {
    display: flex;
  }
}

.map-button-content {
  font-weight: 700;
  font-size: 1.4rem;
}

.map-back {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: $darkest-grey;
  font-size: 1.4rem;
  line-height: 2.1rem;
}

.back-icon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.7rem;
}
