@import 'assets/css/common.scss';

.dayview-navigation {
  display: flex;
  padding: 0 2.4rem;
  margin-top: 2.2rem;

  &-header {
    padding: 0 2.4rem;
    grid-template-columns: repeat(5, 8.8rem);
    column-gap: 1rem;

    @include mobile {
      padding: 0 0.1rem;
      grid-template-columns: repeat(3, 10rem);
    }
  }
  &-body {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    margin-right: 0rem;
    margin-bottom: 1rem;
    position: relative;
    grid-template-columns: repeat(5, 8.8rem);
    column-gap: 1rem;

    button {
      height: 3.6rem;

      &:hover {
        border: 1px solid $magenta;
        background-color: $white;

        @include mobile {
          border: none;
        }
      }

      &:active {
        @include mobile {
          border: 1px solid $magenta;
        }
      }
    }

    @include mobile {
      position: relative;
      padding: 0 0.1rem;
      padding-top: 1rem;
      margin: 0;
      grid-template-columns: repeat(3, 10rem);
    }
  }
  &-blur-top-bg {
    width: calc(100% - 2.4rem);
    background: linear-gradient(
      360deg,
      rgba(249, 249, 249, 0.2) 0%,
      $white 100%
    );
  }
  &-blur-bottom-bg {
    width: calc(100% - 4.8rem);
    margin-left: 2.4rem;
    background: linear-gradient(
      180deg,
      rgba(249, 249, 249, 0) 0%,
      rgba(254, 254, 254, 0.85) 74.48%,
      #ffffff 100%
    );
    bottom: 1rem;
    @include mobile {
      bottom: 0;
    }
  }
  &-icon {
    left: calc(50% - 1rem);
  }
}
