@import 'assets/css/common.scss';

.container {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: fill;
  &-wrapper {
    border-radius: 50%;
    width: 4.8rem;
    height: 4.8rem;
    overflow: hidden;
    margin-right: 1.6rem;
  }
}

.name {
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.distance {
  font-weight: 700;
}

.info-section {
  display: flex;
  align-items: center;
  flex: 1;
  line-height: 24px;
}

.dot {
  margin: 0 0.5rem;
}

.rating-stars {
  padding-bottom: 0.5rem;
}

.average-score {
  margin: 0 0.7rem 0 0.9rem;
}

.info-icon {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1.4rem;

  @include mobile {
    margin-right: 1.2rem;
  }
}

.contact-info {
  margin-top: 2rem;
  &-item {
    margin-top: 0.8rem;
  }
}

.view-availability-button {
  height: 3.2rem;
  width: 15rem;
  align-self: center;
  margin-top: 1.8rem;
  padding: 0;
}

.flex {
  display: flex;
  flex-direction: row;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.bold {
  font-weight: 700;
  line-height: 2.4rem;
}
