@import 'assets/css/common.scss';

.container {
  margin: 2.8rem 0 1.6rem 0;
  @include mobile {
    width: 80%;
  }
  .title {
    font-weight: 700;
    font-size: $fontSize-mml;
    line-height: 3rem;
    margin: 0.8rem 0 0 0;
  }
}