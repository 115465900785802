@import 'assets/css/common.scss';

.container {
  width: 100%;
  height: 11rem;
  color: $primary-purple;
  z-index: 900;
  background-color: $white;
  @include mobile {
    height: 6.4rem;
    z-index: 2;
  }
  &--right {
    display: flex;
  }
  &--left {
    font-size: $fontSize-l;
    text-decoration: none;
  }
}

.children {
  flex-basis: 88rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include mobile {
    @include flex-column-gap(1rem);
    justify-content: flex-start;
  }
}

.logo {
  width: 14.7rem;
  height: 4rem;
  background-image: url('../../assets/images/logo.svg');
  @include mobile {
    width: 8.8rem;
    height: 2.4rem;
    background-size: contain;
  }
  &.logo-icon-only {
    @include mobile {
      width: 2.4rem;
      height: 2.4rem;
      background-image: url('../../assets/icons/logo-icon.svg');
    }
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mobile {
    justify-content: unset;
    @include flex-column-gap(1rem);
  }
}

.space-between {
  justify-content: space-between;
}

.hidden {
  display: none;
}

.order-last {
  order: 1;
  @include mobile {
    display: none;
  }
}

.drawer-root {
  width: 60%;

  .navigation-container {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    height: 100%;
  }

  .navigation-link {
    font-family: 'Nexa';
    font-weight: 400;
    font-size: $fontSize-s;
    text-decoration: none;
    line-height: 2.1rem;
    color: $neutral-title;
    text-align: start;
    margin-top: 3rem;
    &:first-child {
      margin-top: 0rem;
    }
  }
  .navigation-sign-out {
    margin-top: auto;
    display: flex;
    align-items: center;
    @include flex-column-gap(0.8rem);
  }
}
