@import 'assets/css/common.scss';

.timeslot-header {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1rem;
  margin-top: 0.7rem;
  @include mobile {
    grid-template-columns: repeat(3, 1fr);
  }
}

.timeslot-body {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1rem;
  margin-top: 1rem;
  &-container > :not(:last-child) {
    margin-bottom: 1rem;
  }
  @include mobile {
    grid-template-columns: repeat(3, 1fr);
  }
}

.timeslot-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include flex-row-gap(0.4rem);

  & > span:first-child {
    font-weight: 400;
  }
  & > span:nth-child(2) {
    font-weight: 700;
  }
}

button.timeslot-cell {
  font-family: 'Nexa';
  background: $white;
  display: flex;
  height: 3.6rem;
  justify-content: center;
  align-items: center;
  line-height: 2.1rem;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  box-shadow: 0px 0px 5px 2px $smaller-btn-box-shadow;
  color: $darkest-grey;
  font-weight: 400;
  font-size: $fontSize-s;
  &:hover {
    border: 1px solid $magenta;

    @include mobile {
      border: none;
    }
  }

  &:active {
    border: 1px solid $magenta;
  }

  &[data-empty='true'] {
    box-shadow: none;
    cursor: default;
    &:hover {
      border-color: transparent;
      background-color: transparent;
    }
  }
}

.blur-background {
  background: linear-gradient(
    180deg,
    rgba(254, 253, 253, 0.42) 0%,
    #fefefe 42.19%,
    #fff 100%
  );
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-more-btn {
  width: 15rem;
  height: 3.2rem;
}
