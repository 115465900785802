@import 'assets/css/common.scss';

.upper-section {
  position: relative;
  padding-bottom: 11rem;
}

.body {
  margin-top: 4.4rem;
  font-size: 1.4rem;
  line-height: 2.1rem;
}
