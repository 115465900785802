button {
  color: unset;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  font-family: 'Nexa';
  box-sizing: border-box;
}

body {
  margin: 0;
  /* height: 100vh; */
}

/* #root {
  height: 100%;
} */

*,
*:before,
*:after {
  box-sizing: border-box;
}

.shadow-tooltip.place-bottom::before {
  border-bottom-color: #e8e8e8 !important;
}

.shadow-tooltip.place-bottom::after {
  border-bottom-color: #f9f9f9 !important;
}

.shadow-tooltip.place-right::before {
  border-right-color: #e8e8e8 !important;
}

.shadow-tooltip.place-right::after {
  border-right-color: #f9f9f9 !important;
}

.toast {
  border: none;
  font-size: 1.4rem;
  text-align: left;
  box-shadow: none !important;
  padding: 0 !important;
  line-height: 2.1rem !important;
  max-width: 67rem !important;
}

.toast > * {
  margin: 0 !important;
  justify-content: flex-start;
}
