@import 'assets/css/common.scss';

.container {
  background-color: $white;
  border-radius: 1.6rem;
  padding: 3rem 0;
  display: flex;
  position: relative;
  border: 1px solid transparent;
  box-shadow: 0px 0px 5px 2px rgba(230, 230, 230, 0.4);
  @include mobile {
    flex-direction: column;
    padding: 2rem 0;
  }
}

.active {
  border: 1px solid $magenta;
}

.hover-cursor {
  cursor: pointer;
}

.avatar {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: $magenta;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile {
    width: 4rem;
    height: 4rem;
    align-self: flex-start;
  }
}

.left-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include flex-row-gap(0.8rem);
  border-right: 4px solid $lighter-grey;
  flex: 1;
  @include mobile {
    flex-direction: row;
    padding-left: 2rem;
    justify-content: flex-start;
    @include flex-column-gap(1.6rem);

    border-right: none;
  }
  &-iframe {
    flex: none;
    flex-basis: 17rem;
    @include mobile {
      flex-basis: auto;
    }
  }
}

.full-name {
  font-weight: 700;
  font-size: $fontSize-ml;
  line-height: 2.7rem;
  color: $darkest-grey;
  text-align: center;
  word-break: break-word;
  max-width: 150px;
  @include mobile {
    text-align: left;
    margin-top: 0;
  }
}

.right-section {
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  margin: auto 0;
  justify-content: space-between;
  padding-left: 3rem;
  padding-right: 3rem;

  & > :nth-child(3) {
    margin-top: 2rem;
  }

  @include mobile {
    padding-left: 2rem;
    margin-top: 2rem;
    @include flex-row-gap(1rem);

    & > :nth-child(3) {
      margin-top: 1rem;
    }
  }
}

.user-info {
  display: flex;
  @include flex-column-gap(0.8rem);

  color: $neutral-title;
  .user-detail {
    font-weight: 700;
    font-size: $fontSize-s;
    line-height: 2.1rem;
  }
  .user-data {
    font-weight: 400;
    font-size: $fontSize-s;
    line-height: 21px;
  }
}

.control-radio {
  position: absolute;
  top: 1.8rem;
  left: 3rem;
  @include mobile {
    left: unset;
    right: 1.6rem;
    margin-right: 0;
  }
}
