@import 'assets/css/common.scss';

.container {
  flex: 1;
  padding: 0 0.9rem;
  display: flex;
  flex-direction: column;
  .center {
    margin: 1.2rem auto 0 auto;
    color: $magenta;
    text-decoration: underline;
    display: flex;
    font-weight: 700;
    cursor: pointer;
    &-icon {
      margin-right: 0.8rem;
    }
  }
  @include mobile {
    padding: 0;
    .center {
      margin: 2.4rem auto 0 auto;
    }
  }
}

.time-slots {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  row-gap: 1rem;
  column-gap: 1rem;
  height: max-content;

  &-wrapper {
    display: flex;
    justify-content: space-evenly;
  }
  .div {
    display: flex;
    justify-content: center;
  }
  @include mobile {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
