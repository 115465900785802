@import 'assets/css/common.scss';

.container {
  width: 70rem;
  font-size: $fontSize-s;
  line-height: 2.1rem;
  margin: 0 auto;
  background-color: $white;
  border: 1px solid $lighter-grey;
  border-radius: 16px;
  box-sizing: border-box;

  @include mobile {
    width: 100%;
    border: none;
    border-radius: 0;
    margin-top: 10.4rem;
    background-color: transparent;
    padding: 0 2.4rem;
  }
}

.wrapper {
  padding-top: 4rem;
  padding-bottom: 3.8rem;
  color: $darkest-grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;

  @include mobile {
    padding: 4rem 2.4rem;
    background-color: $white;
    box-shadow: 0px 0px 5px 2px rgba(230, 230, 230, 0.4);
    border-radius: 1rem;
    max-width: none;
  }
}

.text {
  font-weight: 700;
  font-size: $fontSize-m;
  line-height: 2.4rem;
}

.container-link {
  display: flex;
  flex-direction: column;
  @include flex-row-gap(1.7rem);
  align-items: center;
}

.btn-email {
  width: 25rem;
}

.link-home {
  font-weight: 700;
  text-decoration: underline !important;
  color: $magenta !important;
}
