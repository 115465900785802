@import 'assets/css/common.scss';

.container {
  font-size: $fontSize-s;
  line-height: 2.1rem;
  margin-bottom: 2.4rem;
  margin-bottom: 1.8rem;
  margin-top: 1.4rem;

  &--left {
    display: flex;
    flex-direction: column;
    align-items: center;
    .avatar {
      height: 6rem;
      width: 6rem;
      object-fit: cover;
      border-radius: 100%;
      @include mobile {
        width: 4rem;
        height: 4rem;
      }
    }
  }
  &--right {
    margin-left: 1.6rem;

    @include mobile {
      margin-left: 2.4rem;
    }

    .name {
      font-size: $fontSize-xl;
      line-height: 5.4rem;
      @include mobile {
        font-size: $fontSize-mml;
        line-height: 3rem;
      }
    }
  }
}

.contact-infos {
  display: flex;
  align-items: center;
  padding: 0.8rem 0 0.6rem 0;
  position: relative;
  &--value {
    margin-left: 2.6rem;
  }
  @include mobile {
    display: none;
  }
  &.hide-on-desktop {
    display: none;
    @include mobile {
      display: flex;
      align-items: center;
      padding: 0.8rem 0 0.6rem 0;
    }
  }
  a:-webkit-any-link.contact-infos--value {
    text-decoration: underline;
    color: $magenta;
  }
}

.flex-row {
  display: flex;
}

.icon {
  position: absolute;
  top: 1rem;
}

.dot-icon {
  margin: 0 1.1rem;
  color: $grey;
}

.bold {
  font-weight: 700;
}

.secondary-purple {
  color: $secondary-purple;
  font-size: $fontSize-s;
}

.rating {
  margin-top: 0.8rem;
  align-self: center;
  &-container {
    display: flex;
    align-items: center;
    @include mobile {
      display: none;
    }
  }
  &-score {
    font-weight: 700;
    margin: 0 0 0 1.6rem;
  }
  &-stars {
    padding-bottom: 0.5rem;
  }
}
