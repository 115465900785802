@import 'assets/css/common.scss';

.container {
  width: 82rem;
  margin: 0 auto;
  position: relative;

  @include mobile {
    width: 100%;
  }

  &-iframe {
    width: 100%;
    padding: 0 2.8rem;
    @include mobile {
      padding: 0;
    }
  }
}

.patient-list-section {
  margin-bottom: 6rem;

  @include mobile {
    margin-bottom: 4rem;
  }
}

.card-container {
  @include flex-row-gap(1.6rem);
  @include mobile {
    padding: 0 2rem;
    &:last-child {
      margin-bottom: 3rem;
    }
  }
}

.detail-section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @include mobile {
    margin-top: 0;
  }
}



.title {
  @include flex-column-gap(1.4rem);

  font-weight: 700;
  font-size: $fontSize-m;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  max-width: 45rem;
  align-items: baseline;

  &-select {
    font-weight: 400;
    font-size: $fontSize-xs;
    line-height: 18px;
    color: $darkest-grey;

    @include mobile {
      margin-left: 0;
      margin-top: 0.5rem;
    }
  }

  @include mobile {
    flex-direction: column;
  }
}

.description-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.6rem;

  @include mobile {
    flex-direction: column;
    padding: 0 2rem;
    margin-bottom: 1rem;
  }
}

.description {
  font-weight: bold;
  font-size: $fontSize-m;
  line-height: 24px;
  margin-top: 3rem;
}