@import 'assets/css/common.scss';

.container {
  padding: 0 0 1.6rem 0;
  @include mobile {
    width: 80%
  }
  .title {
    font-weight: 700;
    font-size: $fontSize-m;
    margin: 1.6rem 0 0 0;
  }
}
