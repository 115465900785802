@import 'assets/css/common.scss';

.otp-form {
  @include mobile {
  }
}

.title {
  padding: 1.6rem 0 0.8rem 3rem;
  font-size: $fontSize-mml;
  line-height: 3rem;
  border-bottom: 1px solid $lighter-grey;
  font-weight: 700;
  @include mobile {
    font-size: $fontSize-m;
  }
}

.section {
  line-height: 2.1rem;
  font-size: 1.4rem;
  color: $darkest-grey;
  @include mobile {
    padding-right: 3.6rem;
  }
}

.code-input {
  margin-top: 1.6rem;
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  input {
    font-family: monospace;
    border-radius: 6px;
    border: 1px solid $grey;
    box-sizing: border-box;
    padding-left: 1.1rem;
    width: 4.2rem;
    height: 4.8rem;
    font-size: 32px;
    color: $text-dark;
    background-color: $white;
    outline-color: $magenta;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    @include mobile {
      width: 3.7rem;
      font-size: 24px;
      padding-left: 1rem;
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
  input[type='number']:focus {
    border-color: $magenta;
    outline: 0 none;
  }
}

.button {
  display: flex;
  height: 4rem;
  margin-left: auto;
  margin-top: 3.2rem;

  @include mobile {
    width: 15rem;
  }
}
