@import 'assets/css/common.scss';

.modal-wrapper {
  display: flex;
  width: 90rem;
  display: flex;
  height: 77rem;
  @include mobile {
    width: unset;
    height: auto;
  }
}

.circular-loader-wrapper {
  width: 100%;
  text-align: center;

  .circular-loader {
    margin: 0 auto;
  }
}

.card-loading {
  @extend .circular-loader-wrapper;
  margin-top: 3rem;
}

.no-result {
  font-size: $fontSize-m;
  font-weight: 400;
  color: $darkest-grey;
  margin-bottom: 2.4rem;
  @include mobile {
    padding: 0 2rem;
  }
}

.latest-availability {
  font-size: $fontSize-m;
  font-weight: 700;
  color: $darkest-grey;
  margin-bottom: 1.6rem;
  @include mobile {
    padding: 0 2rem;
  }
}

.card {
  margin-bottom: 1.6rem;
}

.body-container {
  display: -webkit-box;
  max-width: 22.6rem;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-top: 1rem;
}

.line {
  width: 42px;
  height: 1px;
  background: #c2c1c1;
  margin: 1.6rem 0;
}

.text {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.1rem;
  color: $darkest-grey;
}

.day-view-wrapper {
  .blur-background {
    background: linear-gradient(
      180deg,
      rgba(254, 253, 253, 0.42) 0%,
      #fefefe 42.19%,
      #fff 100%
    );
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .view-more-btn {
    width: 15rem;
    height: 3.2rem;
  }
}

.month-view-wrapper {
  text-align: center;
  .view-more-btn {
    margin-top: 1rem;
    width: 15rem;
    height: 3.2rem;
  }
}

.center-content {
  display: flex;
  justify-content: center;
}

.load-more-button {
  width: 20rem;
  height: 4rem;
  & > span > [role='progressbar'] {
    color: $magenta;
  }
}

.no-more-result {
  font-size: 1.4rem;
  line-height: 2.1rem;
}

.filter-no-result {
  font-size: 1.6rem;
  line-height: 2.4rem;
  button {
    margin-left: 0.8rem;
    font-size: inherit;
    line-height: inherit;
    text-decoration: underline;
    color: $magenta;
    font-weight: 700;
    cursor: pointer;
  }
  @include mobile {
    padding: 0 2rem;
  }
}
