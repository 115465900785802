@import 'assets/css/common.scss';

.container {
  display: flex;
  width: 100%;
  background-color: $white;
  height: 4.8rem;
  padding-left: calc((100% - 128rem) / 2);
  align-items: center;
  border-bottom: 1px solid $lighter-grey;

  @include flex-column-gap(50rem);

  @include mobile {
    padding: 0 2rem;
    justify-content: space-between;
    @include flex-column-gap(0rem);
  }
}

.remove-border {
  border-bottom: 1px solid transparent;
}

.navigator {
  flex-basis: 15rem;
}

.controller {
  color: $darkest-grey;
  display: flex;
  @include flex-column-gap(1.2rem);
  &-view {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.8rem;
    padding: 0 8px;
    height: 2.4rem;
    border-radius: 0.8rem;
    background-color: $white;
    box-shadow: 0px 0px 5px 2px $box-shadow-elevation-07;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    @include flex-column-gap(0.6rem);

    &[data-active='true'] {
      border: 1px solid $magenta;
      box-shadow: 0px 0px 5px 2px $box-shadow-elevation-10-hover;
      color: $magenta;
      svg g path {
        fill: $magenta;
      }
    }
    &-filter-icon {
      width: 2rem;
      height: 2rem;
    }
    &-icon {
      width: 1rem;
      height: 1rem;
      transform: rotate(180deg);
    }
  }
  &-dropdown {
    width: 11.6rem;
    height: 9.5rem;
    border-radius: 1rem;
    background-color: $white;
    box-shadow: 0px 0px 5px 2px $box-shadow-elevation-07;

    padding: 1.6rem;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include flex-row-gap(1.6rem);

    &-item {
      font-size: 1.4rem;
      line-height: 2.1rem;
      cursor: pointer;

      &[data-active='true'] {
        color: $magenta;
      }
      &:hover {
        color: $magenta;
      }
    }
  }
}
