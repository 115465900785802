@import 'assets/css/common.scss';
.container {
  width: 100%;
  height: 6rem;
  background-color: #f5ecf5;
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 0;
  z-index: 999999;
  span {
    font-family: 'Nexa';
    color: $magenta;
    margin-right: 1rem;
    font-size: 1.3rem;
    line-height: 2.1rem;
    margin-top: 0.5rem;
  }
  svg {
    width: 6.9rem;
    height: 1.9rem;
  }
}
