@import 'assets/css/common.scss';

.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 60px;
  background-color: $white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  padding-left: 28px;
  padding-right: 28px;

  @include mobile {
    padding-left: 24px;
    padding-right: 24px;
    box-shadow: 0px 4px 8px 4px $light-box-shadow;
  }
}

.avatar {
  width: 28px;
  height: 100%;
  max-height: 24px;
}