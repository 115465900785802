@import 'assets/css/common.scss';

.body-section {
  margin-top: 3rem;

  @include mobile {
    margin-top: 0;
  }

  & > :first-child {
    margin-bottom: 2.4rem;

    @include mobile {
      margin-bottom: 1.6rem;
    }
  }
}

.footer-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  font-size: $fontSize-s;

  @include mobile {
    margin-top: 3rem;
  }
}

.click-sign-in {
  cursor: pointer;
}

.social-section {
  display: flex;
  margin-top: 1.8rem;
  @include flex-column-gap(4rem);
  @include mobile {
    margin-bottom: 1.5rem;
  }
}

.button {
  display: flex;
  height: 4rem;
  margin-left: auto;
  margin-top: 3.2rem;

  @include mobile {
    width: 100%;
  }
}
