@import 'assets/css/common.scss';

.header {
  @include mobile {
    display: flex;
  }
}

.card-self {
  @include mobile {
    align-self: flex-start;
    flex-shrink: 0;
    margin-right: 1.6rem;
  }
}

.detail {
  display: none;
  @include mobile {
    display: block;
    font-size: 1.4rem;
    line-height: 2.1rem;
    &-title {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.7rem;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &-specialty {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-top: 0.5rem;
    }
    &-info {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
    }
    .distance {
      font-weight: 700;
      margin-left: 6.8px;
    }
    .icon {
      margin-left: 2rem;
    }
  }
}
