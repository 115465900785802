@import 'assets/css/common.scss';

.container {
  background-color: $white;
  border-radius: 1.6rem;
  padding: 3rem 0;
  display: flex;
  position: relative;
  border: 1px solid transparent;
  box-shadow: 0px 0px 5px 2px $smaller-btn-box-shadow;

  @include mobile {
    flex-direction: column;
    padding: 2rem 0;
  }
}

.container-poc {
  padding-top: 4.5rem;
}


.point-of-contact {
  position: absolute;
  top: 15px;
  left: 20px;
  color: $light-dark;
  font-size: $fontSize-xxs;
  font-weight: 700;
}

.avatar {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: $magenta;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile {
    width: 4rem;
    height: 4rem;
    align-self: flex-start;
  }
}

.left-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include flex-row-gap(0.8rem);
  border-right: 4px solid $lighter-grey;
  flex: 1;
  width: 100%;
  max-width: 18rem;
  min-width: 18rem;

  @include mobile {
    flex-direction: row;
    padding-left: 2rem;
    justify-content: flex-start;
    @include flex-column-gap(1.6rem);

    border-right: none;
  }
}

.full-name {
  font-weight: 700;
  font-size: $fontSize-ml;
  line-height: 2.7rem;
  color: $darkest-grey;
  text-align: center;
  word-break: break-word;
  max-width: 150px;
  @include mobile {
    text-align: left;
    margin-top: 0;
    max-width: none;
  }
}

.right-section {
  flex: 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  row-gap: 1.6rem;
  padding-left: 3rem;
  @include mobile {
    grid-template-columns: 1fr;
    padding-left: 2rem;
    margin-top: 2rem;
    row-gap: 1rem;

    & > :nth-child(3) {
      margin-top: 1rem;
    }
  }

  .patient-info-section,
  .future-appt-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mobile {
      justify-content: flex-start;
    }

    .bold {
      font-weight: 700;
      margin-bottom: 1rem;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }
}