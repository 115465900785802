@import 'assets/css/common.scss';

.container {
  @include mobile {
    margin-top: 4rem;
    padding: 0 2.4rem;
  }
}

.divider {
  margin: 0 0 2.4rem 0;
}

.title {
  font-weight: 700;
  font-size: $fontSize-mml;
  line-height: 3rem;
  color: $secondary-purple;
  margin-bottom: 1.6rem;
}
