@import 'assets/css/common.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 2.4rem;
  @include mobile {
    padding: 0;
  }
}

.left {
  display: flex;
  flex-direction: column;
  margin-right: 3.2rem;
  flex: 2;
  position: relative;
  @include mobile {
    margin-right: 0;
    padding: 2.4rem;
    padding-top: 3.9rem;
  }
}

.right {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  flex: 1;
  @include mobile {
    display: none;
  }
}

.clinic-info-wrapper {
  display: flex;
  height: max-content;
  @include mobile {
    align-items: center;
  }
}

.card-avatar {
  width: 10rem;
  height: 10rem;
  @include mobile {
    width: 6rem;
    height: 6rem;
  }
}

.circular-progress {
  margin: auto auto;
}

.divider {
  margin: 1.6rem 0;
  width: 100%;
}

.time-blocks-wrapper {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 0 2.4rem;
}

.dayview-navigation {
  display: flex;
  padding: 0 2.4rem;
  margin-top: 2.2rem;
  &-header {
    padding: 0 2.4rem;
    @include mobile {
      padding-right: 0;
      margin-right: 1rem;
      margin-top: 0;
    }
  }
  &-body {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    margin-right: 1.5rem;
    margin-bottom: 4.9rem;
    @include mobile {
      position: relative;
      padding-right: 0;
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }
  &-blur-top-bg {
    width: calc(100% - 2.4rem);
    background: linear-gradient(
      360deg,
      rgba(249, 249, 249, 0.2) 0%,
      $white 100%
    );
  }
  &-blur-bottom-bg {
    width: calc(100% - 4.8rem);
    margin-left: 2.4rem;
    background: linear-gradient(
      180deg,
      rgba(249, 249, 249, 0) 0%,
      rgba(254, 254, 254, 0.85) 74.48%,
      #ffffff 100%
    );
    bottom: 4.2rem;
    @include mobile {
      bottom: 0;
    }
  }
  &-icon {
    left: calc(50% - 1rem);
  }
}

.navigation-container {
  margin-top: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .navigation-section {
    display: flex;
    @include flex-column-gap(1rem);
  }
  .navigation-btn {
    font-family: 'Nexa';
    font-style: normal;
    font-weight: 700;
    font-size: $fontSize-s;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    border: none;
    border-radius: 8px;
    background-color: #fff;
    padding: 0.45rem 1.8rem;
    cursor: pointer;
    box-shadow: 0px 0px 5px 2px $smaller-btn-box-shadow;
    &-inactive {
      background-color: transparent;
      color: $darkest-grey;
      font-weight: 400;
      box-shadow: none;
    }
  }
}

.info {
  @include mobile {
    display: none;
  }
}
