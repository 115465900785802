@import 'assets/css/common.scss';

.container {
  position: relative;
}

.badge {
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  top: 0.6rem;
  left: 1.2rem;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: $fontSize-s;
  line-height: 2.1rem;
}