@import 'assets/css/common.scss';

.action {
  padding: 1.2rem 2.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include flex-column-gap(1.6rem);
  background-color: $white;

  box-shadow: 0px 2px 10px 2px $navigation-bottom-box-shadow;
}

.reset-btn {
  width: 8.8rem;
  height: 3.2rem;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $magenta;
  border: 1px solid $magenta;
  &:disabled {
    border: 1px solid currentColor;
  }
}

.apply-btn {
  width: 8.8rem;
  height: 3.2rem;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  padding: 0.6rem 2.5rem;
}
