@import 'assets/css/common.scss';

.input {
  &_container {
    margin: 0.8rem 0 1.6rem 0;
    &.sub-field {
      max-width: 10rem;
      margin: 0.8rem 0 0.3rem 0;
    }
  }

  &_title {
    font-size: $fontSize-m;
    font-weight: 700;
    &.sub-field {
      font-size: $fontSize-s;
      font-weight: 300;
    }
    margin: 0 0;
    margin-right: 0.2rem;
    margin-bottom: 0.8rem;
  }
}
