@import 'assets/css/common.scss';

.container {
  width: 82rem;
  margin: 0 auto;

  &-iframe {
    width: 100%;
    padding: 0 2.8rem;
  }

  @include mobile {
    width: 100%;
    padding: 0;
  }
}

.patient-form-container {
  background-color: $white;
  border: 1px solid transparent;
  border-radius: 1.6rem;
  box-shadow: 0px 0px 5px 2px rgba(220, 220, 220, 0.5);
  @include mobile {
    margin: 0rem 1.6rem;
  }
}

.headerSection {
  padding: 1.6rem 3rem 1.4rem;

  border-bottom: 1px solid $lighter-grey;

  &-iframe {
    padding: 1.3rem 3rem 1rem;
  }

  @include mobile {
    padding-left: 2rem;
    padding-bottom: 1.6rem;
  }

  .title {
    line-height: 3rem;
    font-weight: bold;
    font-size: $fontSize-mml;
    @include mobile {
      font-size: $fontSize-m;
    }
  }
}

.bodySection {
  padding: 1.6rem 3rem;
  padding-bottom: 6.5rem;

  @include mobile {
    padding: 0.8rem 2rem;
    padding-bottom: 3.8rem;
  }

  &-iframe {
    padding-top: 0.4rem;
    padding-bottom: 2rem;
  }

  & > :first-child {
    margin-bottom: 2.4rem;

    @include mobile {
      margin-bottom: 1.6rem;
    }

    &-iframe {
      margin-bottom: 1.6rem;
    }
  }
}

.action-btn {
  margin: 0 0 0 2.4rem;
  &-container {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    box-shadow: $box-shadow 0px 2px 5px 0px;
    padding: 2rem 8rem;
    @include mobile {
      width: 100%;
      justify-content: space-evenly;
      background-color: $white;
      box-shadow: $box-shadow 0px 2px 5px 0px;
      padding: 2rem 0;
      position: fixed;
      bottom: 0;
    }
  }
}

.footerSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  font-size: $fontSize-s;

  &-iframe {
    margin-top: 3rem;
  }

  @include mobile {
    margin-top: 3rem;
  }
}

.click-sign-in {
  cursor: pointer;
}

.social-section {
  display: flex;
  margin-top: 1.8rem;
  @include flex-column-gap(4rem);
  @include mobile {
    margin-bottom: 1.5rem;
  }
}
