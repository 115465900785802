@import 'assets/css/common.scss';

.container {
  width: 128rem;
  margin-left: auto;
  margin-right: auto;
  @include mobile {
    width: 100vw;
    overflow: auto;
    height: 100%;
  }
}

.header {
  background-color: white;
  @include mobile {
    position: sticky;
    top: 0;
    z-index: 2;
    flex: 1;
  }
  &.fixed {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 900;
  }
  &.sticky {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 900;
  }
  &.has-box-shadow {
    box-shadow: $box-shadow 0px 2px 8px 0px;
    @include mobile {
      box-shadow: none;
    }
  }
  @include mobile {
    .container {
      padding: 0 1.7rem;
      width: auto;
    }
  }
  &.search-result-page {
    position: relative;
    @include mobile {
      position: static;
    }
  }
}

.footer {
  font-size: 1.2rem;
  font-weight: 400;
  border-top: 1px solid #e8e8e8;
  padding-top: 2rem;
  padding-bottom: 8rem;
  width: 128rem;
  margin-left: auto;
  margin-right: auto;

  @include mobile {
    width: 100vw;
    overflow: auto;
    height: 100%;
    padding-left: 1.7rem;
    padding-right: 1.7rem;
  }
}

.content {
  @include mobile {
    margin-top: 0;
  }
  &.header-fixed {
    margin-top: 11rem;
    @include mobile {
      margin-top: 6.4rem;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
}
