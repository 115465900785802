@import 'assets/css/common.scss';

.container {
  font-family: 'Nexa';
  background: $white;
  display: flex;
  width: 13.6rem;
  height: 4rem;
  justify-content: center;
  align-items: center;
  line-height: 2.1rem;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  box-shadow: 0px 0px 5px 2px $smaller-btn-box-shadow;
  color: $darkest-grey;
  font-weight: 400;
  font-size: $fontSize-s;
  &.small {
    width: 9rem;
  }
  &.medium {
    width: 9.9rem;
  }
  &.highlight {
    background-color: $magenta;
    color: $white;
    &:hover {
      background-color: $magenta;
    }
  }
  &:hover {
    background-color: $light-pink;
    border-color: $light-pink;
  }
  @include mobile {
    width: 100%;
  }
}
