@import 'assets/css/common.scss';

.guidance {
  background-color: $light-grey;
  padding: 1.7rem 2.4rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 5px 2px rgba(220, 220, 220, 0.5);
  font-size: $fontSize-s;

  .title {
    color: $darkest-grey;
    font-weight: bold;
  }

  .validation-container {
    margin-top: 0.4rem;
    padding-left: 1rem;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    @include flex-row-gap(0.4rem);
    li {
      display: flex;
      align-items: center;
    }

    .errorName {
      margin-left: 9px;
    }

    .success {
      color: $secondary-green;

      svg {
        width: 1rem;
        height: 12px;
      }
    }

    .error {
      color: $secondary-red;

      svg {
        width: 1rem;
        height: 5px;
      }
    }
  }
}
