@import 'assets/css/common.scss';

.wrapper {
  width: 100%;
  max-width: 910px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 12.5rem;
  line-height: 21px;
  @include mobile {
    margin-top: 8rem;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 420px;
}

.empty-text {
  font-size: $fontSize-s;
  font-weight: 400;
  text-align: center;
  margin-top: 16px;
  @include mobile {
    max-width: 80%;
  }
}

.next-availability-btn {
  width: 100%;
  max-width: 320px;
  margin-top: 38px;
  text-transform: initial;
}

.sign-up-btn {
  width: 100%;
  max-width: 200px;
  text-transform: initial;
}
