@import 'assets/css/common.scss';

.container {
  background-color: $light-grey;
  font-family: 'Nexa';
  height: 100%;
}

.container-iframe {
  background-color: $white;
}

.content-container {
  padding: 3rem 0 0 0;
  display: flex;
  flex-direction: column;
  font-size: $fontSize-s;
  line-height: 2.1rem;
  padding-bottom: calc(80px + 90px);
  background-color: $light-grey;
  position: relative;

  @include mobile {
    width: auto;
    padding: 0 0 17rem 0;
  }
}

.content-container-iframe {
  background-color: $white;
  padding-top: 0;
  margin-top: 6rem;
}