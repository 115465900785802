@import 'assets/css/common.scss';
.root {
  height: 60dvh;
  margin-top: auto;
}

.modal-container {
  padding: 2rem 2.4rem;
  font-family: 'Nexa';
  overflow: hidden;
  * {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    * {
      font-weight: 700;
    }

    button {
      color: $magenta;
    }
  }

  .body {
    margin-top: 3.8rem;

    .column:first-child {
      .item {
        justify-content: flex-start !important;
        transform: translateX(3.6rem);
      }
    }

    .column:nth-child(3) {
      .item {
        justify-content: flex-end !important;
        transform: translateX(-3.6rem);
      }
    }
  }

  .picker-container {
    width: calc(100% + 2.4rem * 2);
    transform: translateX(-2.4rem);
    > :nth-child(4) {
      background-color: $light-grey;
      z-index: -1;
    }
  }

  .selected {
    font-size: 1.8rem;
    line-height: 2.7rem;
    font-weight: 700;
  }
}
