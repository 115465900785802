@import 'assets/css/common.scss';

.flex-row {
  display: flex;
}

.contact-infos {
  display: flex;
  padding-bottom: 0.6rem ;
  margin-bottom: 2.4rem;
  @include flex-column-gap(0.8rem);
}

.grid {
  display: grid;
  column-gap: 1rem;
  row-gap: 2rem;
  grid-template-columns: minmax(8.1rem, 1fr) 2fr;
}

.icon {
  flex-shrink: 0;
}

.row {
  display: flex;
  flex-direction: row;
}

.bold {
  font-weight: 700;
}