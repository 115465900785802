@import 'assets/css/common.scss';

.base {
  text-align: left;
  width: 100%;
  padding: 1.8rem;
  border: 1px solid;
  border-radius: 1rem;
}

.success {
  @extend .base;
  background-color: #eafde7;
  border-color: #83d972;
  color: $secondary-green;
}

.error {
  @extend .base;
  background-color: $light-red;
  border-color: #ffa39e;
  color: $secondary-red;
}

.warning {
  @extend .base;
  background-color: #fff9ee;
  border-color: #ffc656;
  color: #ffc656;
}

.info {
  @extend .base;
  background-color: #f4faff;
  border-color: #4d97e1;
  color: #4d97e1;
}

.container {
  display: flex;
  @include flex-column-gap(1.8rem);
}

.icon {
  width: 2rem;
  height: 2rem;
}

.success-icon {
  @extend .icon;
  path {
    fill: $secondary-green;
  }
}

.error-icon {
  @extend .icon;
  path {
    fill: $secondary-red;
  }
}

.warning-icon {
  @extend .icon;
  path {
    fill: #ffc656;
  }
}

.info-icon {
  @extend .icon;
  path {
    fill: #4d97e1;
  }
}

.flex-1 {
  flex: 1;
}

.main-section {
  display: flex;
  justify-content: space-between;
  @include flex-column-gap(1.1rem);
}

.text {
  font-size: 14;
  line-height: 2.1rem;
  color: $darkest-grey;
}

.close-icon {
  align-self: start;
  cursor: pointer;
  svg {
    width: 1.6rem;
    height: 1.6rem;
    path {
      fill: #a9a9a9;
    }
  }
}

.extra-button {
  font-size: inherit;
  font-weight: bold;
  margin-top: 0.6rem;
  text-transform: uppercase;
}
