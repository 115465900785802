@import 'assets/css/common.scss';

.action-btn {
  margin: 0 0 0 2.4rem;

  &-container {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    box-shadow: 0px 2px 10px 2px $navigation-bottom-box-shadow;
    padding: 2rem 8rem;
    z-index: 1;

    @include mobile {
      width: 100%;
      justify-content: flex-end;
      background-color: $white;
      box-shadow: 0px 2px 10px 2px $navigation-bottom-box-shadow;
      padding: 0.8rem 1.6rem 0.8rem 1.6rem;
      position: fixed;
      bottom: 0;

      button {
        width: 100%;
        height: auto;
        padding: 1rem 4rem 0.9rem 4rem;
      }
    }

    &-iframe {
      position: initial;
      box-shadow: none;
      background-color: initial;
      padding: 0rem;
      margin-top: 3rem;
    }
  }
}

.fixed {
  position: fixed;
  display: flex;
  align-items: end;
  width: 100%;
  bottom: 6rem;
  margin: 0rem;
  height: 10rem;
  padding-bottom: 1rem;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 60.94%);
}
